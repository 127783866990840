<template>
  <v-dialog
    :value="value"
    width="400"
    @input="updateValue"
    persistent
    v-bind="$attrs"
    v-on="listeners"
  >
    <v-card>
      <v-card-title class="text-h5">{{ title }}</v-card-title>
      <v-card-text>
        <slot> Are you sure you want to delete? </slot>
      </v-card-text>
      <v-card-actions class="mx-1 pb-5 pt-1">
        <v-row>
          <v-col cols="auto">
            <v-btn color="primary" outlined @click="$emit('cancel')"
              >Cancel</v-btn
            >
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-btn
              color="error"
              :disabled="disabled"
              class="white--text"
              @click="$emit('delete')"
              >{{ submitText }}</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      default: null,
    },
    disabled: {
      default: false,
    },
    title: {
      default: 'Confirm Delete',
    },
    submitText: {
      default: 'Delete',
    },
  },

  methods: {
    updateValue(event) {
      this.$emit('input', event)
    },
  },

  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
      }
    },
  },
}
</script>

<style></style>
