import api from '@/services/axios.js'
import jwt_decode from 'jwt-decode'
import router from '@/router'
import VueCookies from 'vue-cookies'
import Vuetify from '@/plugins/vuetify'

export const namespaced = true

// Number of seconds to ensure user has been active
const TIMEOUT = 30 * 60 // 30 minutes
const OPS_TIMEOUT = 8 * 60 * 60 // 8 hours

export const state = {
  user: null,
  last_active: Date.now(),
  staffMode: false,
  selectedBase: null,
  temp_logout: false,
}

export const mutations = {
  SET_USER(state, data) {
    state.user = data
    state.user.permissions = jwt_decode(state.user.permissions_jwt)

    // If superuser and not developement then set colour to red
    if (state.user.is_superuser && process.env.NODE_ENV !== 'development') {
      Vuetify.framework.theme.themes.light.primary = '#e72020'
    }
  },
  SET_STAFF_MODE(state) {
    state.staffMode = true
    // Also set in localstorage so we can persist the state
    localStorage.setItem('staffMode', true)
  },
  CLEAR_STAFF_MODE(state) {
    state.staffMode = false
    localStorage.removeItem('staffMode')
  },
  LOG_ROUTE_CHANGE(state) {
    state.last_active = Date.now()
  },
  SET_BASE(state, baseID) {
    state.selectedBase = baseID
    localStorage.setItem('base', baseID)
  },
  SET_NOTICE_COUNT(state, count) {
    console.log('Setting notice count to' + count)
    state.user.outstanding_notices_count = count
  },
  SET_TEMP_LOGOUT(state, value) {
    state.temp_logout = value
    state.user = null
  },
}

export const actions = {
  fetchUser({ commit }) {
    return api.get('auth/user/').then((response) => {
      commit('SET_USER', response.data)
    })
  },
  login({ commit, dispatch, getters }) {
    // This assumes we have obtained a cookie by a POST to /auth/login
    // Firstly go and fetch the user data
    return api.get('auth/user/').then((response) => {
      commit('SET_USER', response.data)
      // Set the callback to extend or logout session prior to expiry
      commit('LOG_ROUTE_CHANGE')
      dispatch('QRefresh')
      // Set staff mode if applicable, always default to this mode on login
      if (getters.isStaff) {
        commit('SET_STAFF_MODE')
      } else {
        commit('CLEAR_STAFF_MODE')
      }
    })
  },
  syncAuth({ commit, state, dispatch, getters }) {
    // Call this before each route change

    // Dont do it if we are temporarily logged out (eg for public views)
    // if (state.temp_logout) {
    //   return
    // }

    // Log route change so we cn track when user was last active
    commit('LOG_ROUTE_CHANGE')

    // Ensure we capture the case where browser is refreshed and local storage
    // says we are logged in but there is no user state
    if (!state.user && VueCookies.get('logged_in')) {
      // Can't test for presence of sessionid cookie since http only but server will
      // ensure a simple logged_in cookie is set that we can access
      return api.get('auth/user/').then((response) => {
        commit('SET_USER', response.data)
        dispatch('QRefresh')
        // On a page refresh only set staff mode if it was saved in local storage as preference
        if (getters.isStaff && localStorage.getItem('staffMode')) {
          commit('SET_STAFF_MODE')
        } else {
          commit('CLEAR_STAFF_MODE')
        }
      })
    }
  },
  logout() {
    api.post('auth/logout/').then(() => {
      // Reload back to the index of the site which will clear all js data too
      const href = location.origin
      location.href = href
    })
  },
  unauthorisedLogout() {
    api.post('auth/logout/').then(() => {
      // Since force logged out we will reload to the login screen instead
      const href = location.origin
      location.href = href + '/login'
    })
  },
  QRefresh({ state, dispatch, getters }) {
    // Queue up the extention check
    let check = state.last_active + getters.timeout * 1000
    let timeout = check - Date.now()

    setTimeout(() => dispatch('extendSession'), timeout)
  },
  extendSession({ state, dispatch, commit, getters, rootGetters }) {
    // If has been active then renew session

    // Auto renew in the case where the student record is being edited
    if (
      router.currentRoute.name == 'StudentRecord' &&
      rootGetters['app/editable'].length
    ) {
      commit('LOG_ROUTE_CHANGE') // Simulate a route change to ensure stay active
    }

    // Determine whether to consider the user as active or not
    // Note we give a 1 second buffer to account for the route change that
    // occurs on the initial login
    let active = state.last_active + getters.timeout * 1000 - 60 > Date.now()

    if (active) {
      api.post('auth/extend/').then(() => {
        dispatch('fetchUser').then(() => dispatch('QRefresh'))
      })
    } else {
      dispatch('logout')
    }
  },
  toggleStaffMode({ getters, commit }) {
    if (getters.staffMode) {
      commit('CLEAR_STAFF_MODE')
      router.push({ name: 'Dashboard' })
    } else {
      commit('SET_STAFF_MODE')
      router.push({ name: 'Dashboard' })
    }
  },
  syncBase({ commit, rootGetters }) {
    // Use to sync the selected base on login / refresh

    // Don't try if user not logged in
    if (!state.user) {
      return
    }

    let local_pref = localStorage.getItem('base')
    if (local_pref && local_pref != 'undefined') {
      // Check base is valid and appears in the list of bases
      let bases = rootGetters['app/bases']
      let valid = bases.find((e) => e.id == local_pref)
      // If not valid then set to default
      if (!valid) {
        let defaultBase = rootGetters['app/bases'][0]
        commit('SET_BASE', defaultBase.id)
        return
      }
      commit('SET_BASE', local_pref)
    } else {
      let defaultBase = rootGetters['app/bases'][0]
      commit('SET_BASE', defaultBase.id)
    }
  },
  changeBase({ commit }, base) {
    commit('SET_BASE', base.id)
  },
  tempClearUser({ commit }) {
    // Use when we want to give appearance of logged out but not actually
    // clear the cookie
    commit('SET_TEMP_LOGOUT', true)
  },
}

export const getters = {
  loggedIn() {
    if (state.user) {
      return true
    } else {
      return false
    }
  },
  user(state) {
    return state.user
  },
  userID(state) {
    return state.user ? state.user.id : null
  },
  permissions(state) {
    return state.user ? state.user.permissions.permissions : []
  },
  groups(state) {
    return state.user ? state.user.permissions.groups : null
  },
  permissionsJwt(state) {
    return state.user ? state.user.permissions_jwt : null
  },
  hasPerm(state) {
    return (perm) => {
      // Use during testing to mimic user not having the permission
      let exclude = []
      if (exclude.includes(perm)) {
        return false
      }

      if (state.user) {
        return state.user.permissions.permissions.includes(perm)
      } else {
        return false
      }
    }
  },
  hasGroup(state) {
    return (group) => {
      // Use during testing to mimic user not having the permission
      let exclude = []
      if (exclude.includes(group)) {
        return false
      }

      if (state.user) {
        return state.user.permissions.groups.includes(group)
      } else {
        return false
      }
    }
  },
  hasPerms(state) {
    return (perms) => {
      let user_perms = state.user.permissions.permissions
      if (state.user) {
        for (let i = 0; i < perms.length; i++) {
          if (!user_perms.includes(perms[i])) {
            return false
          }
        }
        return true
      } else {
        return false
      }
    }
  },
  isStaff(state) {
    if (state.user) {
      let staff_groups = [
        'Operations',
        'Instructor',
        'Examiner',
        'Manager',
        'Engineer',
        'Accounts',
        'Club Account',
      ]
      for (let group of state.user.permissions.groups) {
        if (staff_groups.includes(group)) {
          return true
        }
      }

      // Additional if semet_dev then treat as staff
      if (state.user.permissions.permissions.includes('semet_dev')) {
        return true
      }

      // If get this far then not staff
      return false
    } else {
      return false
    }
  },
  isInstructor(state) {
    if (state.user) {
      return state.user.permissions.groups.includes('Instructor')
    } else {
      return false
    }
  },
  staffMode(state) {
    return state.staffMode
  },
  base(state, getters, rootState, rootGetters) {
    if (!state.selectedBase) {
      return null
    } else {
      let bases = rootGetters['app/bases']
      return bases.find((e) => e.id == state.selectedBase)
    }
  },
  timeout(state, getters) {
    if (getters.hasGroup('Operations')) {
      return OPS_TIMEOUT
    } else {
      return TIMEOUT
    }
  },
}
