const semetRoutes = [
  {
    path: '/voucher-generate',
    name: 'VoucherGenerate',
    meta: { linkTag: 'vouchers' },
    component: () =>
      import(
        /* webpackChunkName: "semet" */ '@/views/semet/VoucherGenerate.vue'
      ),
  },
  {
    path: '/adv',
    name: 'Adventure',
    meta: { linkTag: 'adventure' },
    component: () =>
      import(/* webpackChunkName: "semet" */ '@/views/semet/Adventure.vue'),
  },
  {
    path: '/aex',
    name: 'AEX',
    meta: { linkTag: 'aex' },
    component: () =>
      import(/* webpackChunkName: "semet" */ '@/views/semet/AEX.vue'),
  },
]

export default semetRoutes
