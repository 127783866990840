<template>
  <v-row justify="start" class="on-top">
    <v-col cols="12" sm="">
      <v-btn
        outlined
        color="secondary"
        @click="cancel"
        tabindex="-1"
        :disabled="disabled"
        width="100%"
        >Cancel</v-btn
      >
    </v-col>
    <v-col cols="12" sm="" v-if="showDelete">
      <v-btn
        outlined
        tabindex="-1"
        :disabled="disabled"
        color="warning"
        width="100%"
        @click="confirmDelete = true"
        >Delete</v-btn
      >
    </v-col>
    <v-col cols="12" sm="" v-if="draft">
      <v-btn
        color="secondary"
        outlined
        width="100%"
        @click="$emit('draft')"
        :disabled="disabled"
      >
        Save Draft
      </v-btn>
    </v-col>
    <v-col cols="12" :sm="submitSm">
      <v-btn
        @click="$emit('submit')"
        color="secondary"
        :disabled="disabled"
        type="submit"
        width="100%"
        class="px-8"
      >
        {{ submitText }}
      </v-btn>
    </v-col>
    <BaseDeleteDialog
      :value="confirmDelete"
      @delete="$emit('delete')"
      @cancel="confirmDelete = false"
    />
  </v-row>
</template>

<script>
export default {
  name: 'BaseFormButtons',

  data() {
    return {
      confirmDelete: false,
    }
  },

  props: {
    disabled: {
      required: true,
      type: Boolean,
    },
    draft: {
      required: false,
      default: false,
    },
    showDelete: {
      required: false,
      default: false,
      type: Boolean,
    },
    submitText: {
      required: false,
      default: 'Save',
    },
    editableKey: {
      required: false,
    },
    submitSm: {
      // Set width of the submit button once sm breakpoint is reached
      // Default is to be responsive
      required: false,
      default: '',
    },
  },

  methods: {
    cancel() {
      if (this.editableKey) {
        this.$store.dispatch('app/cancelEdit', this.editableKey)
      }
      this.$emit('cancel')
    },
  },
}
</script>

<style scoped>
/* Needed to force the buttons ontop of the snack bar */
.on-top {
  z-index: 100;
}
</style>
