<template>
  <v-checkbox
    :label="label"
    :input-value="value"
    @change="updateValue"
    v-bind="$attrs"
    v-on="listeners"
  ></v-checkbox>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateValue(event) {
      this.$emit('input', event)
    },
  },

  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
      }
    },
  },
}
</script>
