import store from '@/store'

const techlogRoutes = [
  {
    path: '/techlogs',
    name: 'TechlogHome',
    meta: { linkTag: 'techlog' },
    component: () =>
      import(
        /* webpackChunkName: "techlog" */ '@/views/techlog/TechLogHome.vue'
      ),
    beforeEnter(routeTo, routeFrom, next) {
      store.dispatch('flights/loadOpenFlights').then(() => next())
    },
  },
  {
    path: '/techlogs/ops-add-flight',
    name: 'NewOpsFlight',
    meta: { parent: 'TechlogHome', linkTag: 'techlog' },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "techlog" */ '@/views/techlog/NewOpsFlight.vue'
      ),
  },
  {
    path: '/techlogs/:id',
    name: 'TechLog',
    meta: { parent: 'TechlogHome', linkTag: 'techlog' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "techlog" */ '@/views/techlog/TechLog.vue'),
    beforeEnter(routeTo, routeFrom, next) {
      store
        .dispatch('techlog/loadInstance', { params: routeTo.params })
        .then(() => {
          next()
        })
    },
  },
  {
    // Temporary route to test E-Techlog (will redirect from above route)
    path: '/etechlogs/:id',
    name: 'TechLogETest',
    meta: { parent: 'TechlogHome', linkTag: 'techlog' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "techlogE" */ '@/views/techlog/TechLogE.vue'),
  },
  {
    path: '/techlogs/:id/add-flight',
    name: 'NewFlight',
    meta: { parent: 'TechlogHome', linkTag: 'techlog' },
    props: true,
    component: () =>
      import(/* webpackChunkName: "techlog" */ '@/views/techlog/NewFlight.vue'),
    beforeEnter(routeTo, routeFrom, next) {
      store
        .dispatch('techlog/loadInstance', { params: routeTo.params })
        .then(() => {
          next()
        })
    },
  },
  {
    path: '/flights/:id/edit',
    name: 'EditFlight',
    meta: { parent: 'TechlogHome', linkTag: 'techlog' },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "techlog" */ '@/views/techlog/EditFlight.vue'
      ),
    beforeEnter(routeTo, routeFrom, next) {
      store
        .dispatch('flights/loadInstance', { params: routeTo.params })
        .then(() => next())
    },
  },
  {
    path: '/techlog/close-flight/:id',
    name: 'CloseFlight',
    meta: { parent: 'TechlogHome', linkTag: 'techlog' },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "techlog" */ '@/views/techlog/CloseFlight.vue'
      ),
    beforeEnter(routeTo, routeFrom, next) {
      store
        .dispatch('flights/loadInstance', { params: routeTo.params })
        .then(() => next())
    },
  },
]

export default techlogRoutes
