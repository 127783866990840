import store from '@/store'
// import api from '@/services/axios.js'

const maintenanceRoutes = [
  {
    path: '/maintenance',
    name: 'Maintenance',
    meta: { permissions: [], linkTag: 'maintenance' },
    component: () =>
      import(
        /* webpackChunkName: "members" */ '@/views/maintenance/Maintenance.vue'
      ),
    beforeEnter(routeTo, routeFrom, next) {
      store
        .dispatch(`aircraft/loadList`, { params: routeTo.params })
        .then(() => next())
    },
  },
  {
    path: '/maintenance/aircraft/:id',
    name: 'MaintenanceAircraft',
    meta: { permissions: [], linkTag: 'maintenance' },
    component: () =>
      import(
        /* webpackChunkName: "members" */ '@/views/maintenance/MaintenanceAircraft.vue'
      ),
    beforeEnter(routeTo, routeFrom, next) {
      store
        .dispatch('aircraft/loadInstance', { params: routeTo.params })
        .then(() => next())
    },
  },
  {
    path: '/defects/:id',
    name: 'DefectEdit',
    meta: { linkTag: 'Maintenance' },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "defects" */ '@/views/maintenance/DefectEdit.vue'
      ),
    beforeEnter(routeTo, routeFrom, next) {
      store
        .dispatch('defects/loadInstance', { params: routeTo.params })
        .then(() => {
          next()
        })
    },
  },
  {
    path: '/defects',
    name: 'DefectHistory',
    meta: { linkTag: 'maintenance' },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "maintenance" */ '@/views/maintenance/DefectHistory.vue'
      ),
    beforeEnter(routeTo, routeFrom, next) {
      store
        .dispatch('defects/loadList', { params: routeTo.params })
        .then(() => next())
    },
  },
]

export default maintenanceRoutes
