<template>
  <v-text-field
    v-bind="$attrs"
    v-on="listeners"
    :error-messages="errorMessages"
    :autocomplete="autocomplete"
    @blur="onBlur"
    @keydown.enter="onBlur"
    :hide-details="hideDetails"
    outlined
  >
  </v-text-field>
</template>

<script>
export default {
  inheritAttrs: false,

  data() {
    return {
      inputErrors: [],
    }
  },
  props: {
    errors: {
      required: false,
      default: null,
    },
    autocomplete: {
      type: String,
      required: false,
      default: 'new-nope',
    },
    hideDetails: {
      required: false,
      default: 'auto',
    },
  },
  methods: {
    updateValue() {
      // Do nothing on input as we want to emit only on blur
      return
    },
    onBlur(event) {
      let value = event.target.value
      // If no input then just emit blank and don't validate
      if (value == '') {
        this.$emit('input', value)
        return
      }
      // Auto enter colon if not provided
      if (!value.includes(':')) {
        if (value.length < 2) {
          value = value + ':00'
        } else if (value.length == 2) {
          value = '0:' + value
        } else {
          value = value.slice(0, -2) + ':' + value.slice(-2)
        }
      }

      // Fill in leading zero if not provided
      if (/:\d{1}$/.test(value)) {
        let last_digit = value[value.length - 1]
        value = value.slice(0, -1) + '0' + last_digit
      }
      // Validate in form hh:mm
      if (!/^\d+:[0-5][0-9]$/.test(value)) {
        this.inputErrors.push('h:mm')
        return
      } else {
        // Clear errors if validation passes
        this.inputErrors = []
      }

      this.$emit('input', value)
    },
  },

  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue, // Call a custom input handler
      }
    },
    errorMessages() {
      if (this.errors) {
        let vuelidate = this.errors.map((error) => error.$message)
        return this.inputErrors.concat(vuelidate)
      } else {
        return this.inputErrors
      }
    },
  },
}
</script>

<style></style>
