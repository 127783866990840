<template>
  <div>
    <div
      v-if="labelAbove"
      class="label-above"
      :class="{ 'error--text': errorMessages.length }"
    >
      {{ label }}
    </div>
    <v-text-field
      :outlined="outlined"
      :clearable="box || clearable"
      :hint="hint"
      :label="labelAbove ? null : label"
      :value="value"
      v-bind="$attrs"
      v-on="listeners"
      :autocomplete="autocomplete"
      :prepend-icon="prepend"
      :append-icon="append"
      :error-messages="errorMessages"
      :hide-details="hideDetails"
    >
      <template v-slot:append>
        <slot name="append"></slot>
      </template>
      <template v-slot:label>
        <slot name="label" v-if="$slots.label"> {{ label }}</slot>
      </template>
    </v-text-field>
  </div>
</template>

<script>
//import { formFieldMixin } from '@/mixins/formFieldMixin.js'
export default {
  //mixins: [formFieldMixin],

  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: '',
    },
    errors: {},
    value: [String, Number],
    autocomplete: {
      type: String,
      required: false,
      default: 'new-entry',
    },
    prepend: {
      type: String,
      required: false,
      default: undefined,
    },
    append: {
      required: false,
    },
    hint: {
      required: false,
    },
    clearable: {
      required: false,
    },
    outlined: {
      required: false,
      default: true,
    },
    hideDetails: {
      required: false,
      default: 'auto',
    },
    box: {
      required: false,
      type: Boolean,
    },
    labelAbove: {
      required: false,
      type: Boolean,
    },
  },
  methods: {
    updateValue(value) {
      if (this.$attrs.uppercase) value = value.toUpperCase()
      this.$emit('input', value)
    },
  },

  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
      }
    },
    errorMessages() {
      if (this.errors) {
        return this.errors.map((error) => error.$message)
      } else {
        return []
      }
    },
  },
}
</script>

<style>
/* Ensure custom icons used in the label are fully visible */
.v-input .v-label {
  height: auto !important;
}

.label-above {
  font-size: 1em;
  margin-bottom: 2px;
}
</style>
