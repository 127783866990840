<template>
  <div>
    <v-list-item two-line class="pl-0">
      <v-list-item-content>
        <v-list-item-title class="font-weight-medium">{{
          title
        }}</v-list-item-title>
        <v-list-item-title>
          <slot name="value">
            <span v-if="value" class="value-text">{{ value }}</span>
            <span v-else class="font-weight-light">-</span>
          </slot>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      required: true,
      type: String,
    },
    value: {
      required: false,
      type: String,
    },
  },
}
</script>

<style scoped>
.value-text {
  white-space: break-spaces;
}
</style>
