<template>
  <div>
    <v-autocomplete
      v-bind="$attrs"
      v-on="listeners"
      :error-messages="errorMessages"
      :items="items"
      item-text="list_display"
      item-value="id"
      return-object
      auto-select-first
      outlined
      @keydown="tabNext"
      :hideDetails="hideDetails"
    >
      <template slot="append-outer">
        <v-btn icon @click="dialog = true" tabindex="-1" class="btn-repos"
          ><v-icon>mdi-magnify</v-icon></v-btn
        >
      </template>
    </v-autocomplete>
    <v-dialog v-model="dialog" width="700">
      <v-card>
        <v-card-title class="text-h5 grey lighten-3">
          Airfield Search
        </v-card-title>

        <v-card-text class="mt-4">
          <BaseCrudTable
            moduleName="airports"
            :headers="headers"
            iconType="open"
            :showFilter="false"
            :showOptions="false"
          >
            <template slot="open" slot-scope="{ item }">
              <v-btn icon @click="selectAirport(item)"
                ><v-icon>mdi-plus-circle-outline</v-icon></v-btn
              >
            </template>
          </BaseCrudTable>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="dialog = false"> Cancel </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { tabNext } from '@/helpers/components.js'

export default {
  name: 'BaseAirportSelect',
  props: {
    errors: {
      required: false,
    },
    hideDetails: {
      required: false,
      default: 'auto',
    },
  },

  data() {
    return {
      dialog: false,
      tabNext,
    }
  },

  methods: {
    display(item) {
      console.log(item)
      return `${item.icao} - ${item.name}`
    },
    selectAirport(item) {
      // Set as value
      this.$emit('input', item)
      // Close dialog
      this.dialog = false
    },
  },

  computed: {
    listeners() {
      return {
        ...this.$listeners,
      }
    },
    errorMessages() {
      if (this.errors) {
        return this.errors.map((error) => error.$message)
      } else {
        return []
      }
    },
    items() {
      let value = this.$attrs.value
      let items = this.airports

      // Search items to see if matching object found
      if (value) {
        let found = items.find((e) => e.id == value.id)
        if (value && !found) {
          items.push(value)
        }
      }

      return items
    },
    headers() {
      return [
        { text: 'Name', value: 'list_display', sortable: false },
        { text: 'Country', value: 'iso_country', sortable: false },
        { text: 'Municipality', value: 'municipality', sortable: false },
        { text: '', value: 'open', sortable: false, align: 'right' },
      ]
    },
    ...mapGetters('app', ['airports']),
  },
}
</script>

<style scoped>
/* Prevents the button from increasing the margin below the component */
.btn-repos {
  margin-top: -12px;
  position: relative;
  top: 9px;
}
</style>
