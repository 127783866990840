<template>
  <v-container>
    <v-card class="pt-2 px-2" outlined>
      <v-container fluid class="px-5" v-if="title">
        <v-row>
          <v-col class="text-h6 pb-0"> {{ title }} </v-col>
          <v-col cols="auto"> <slot name="btn"></slot></v-col>
        </v-row>
      </v-container>
      <v-container class="pt-4" v-if="!simple">
        <v-row>
          <v-col class="d-flex align-center">
            <v-text-field
              v-if="search"
              :disabled="hasSpecificFilters"
              @keyup.enter="fetchData"
              @click:append="fetchData"
              :value="searchParams[quickSearch.target].value"
              @input="setSearchParam(quickSearch.target, $event)"
              append-icon="mdi-magnify"
              :placeholder="quickSearchText"
              hide-details
              outlined
              class="pt-0"
            ></v-text-field>
          </v-col>
          <v-col cols="auto d-flex align-center">
            <v-btn
              v-if="allowCsv && hasFilters"
              icon
              @click="$emit('csv')"
              :disabled="disabled || activeDownloads"
              ><v-icon>mdi-microsoft-excel</v-icon></v-btn
            >
            <v-btn
              v-if="allowPdf && hasFilters"
              icon
              @click="$emit('pdf')"
              :disabled="disabled || activeDownloads"
              ><v-icon>mdi-printer</v-icon></v-btn
            >
            <v-btn icon @click="clearFilters"
              ><v-icon>mdi-refresh</v-icon></v-btn
            >
            <v-btn v-if="showFilter" icon @click="searchDialog = true"
              ><v-icon v-if="hasSpecificFilters">mdi-filter</v-icon
              ><v-icon v-else>mdi-filter-outline</v-icon></v-btn
            >
          </v-col>
        </v-row>
      </v-container>
      <v-container class="pb-6" v-if="!showTable">
        <v-row>
          <v-col class="text--secondary font-italic">
            <v-icon class="search-pointer">mdi-arrow-up-left</v-icon>
            <span>
              Please use the quick search above or specific filters to display
              the data
            </span>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="!simple && hasFilters" class="mt-2 mt-sm-0">
        <v-row class="align-center">
          <v-btn v-if="hasFilters" icon class="ml-2" @click="clearFilters"
            ><v-icon>mdi-filter-remove</v-icon></v-btn
          >
          <v-chip
            v-for="(param, index) in currentFilters"
            :key="index"
            small
            class="mx-3"
            @click="searchDialog = true"
          >
            <span class="font-weight-bold mr-1">{{ param.label }}:</span>
            <!-- Expose as slots so can format the text display if needed -->
            <slot :name="`${param.label.toLowerCase()}-filter`"
              ><span class="">{{ param.output }}</span></slot
            >
          </v-chip>
        </v-row>
      </v-container>
      <v-container v-if="showTable">
        <v-row>
          <v-col>
            <v-data-table
              v-bind="$attrs"
              :headers="headers"
              :hide-default-footer="!showFooter"
              :items="list ? list.results : []"
              :loading="fetching"
              :options.sync="options"
              :footer-props="{
                'items-per-page-options': perPageOptions,
              }"
              :server-items-length="list ? list.count : null"
              :mobile-breakpoint="mobileBreakpoint"
              class="basecrud-table"
            >
              <!-- Slot for row rendering -->
              <template
                v-if="$scopedSlots.item"
                v-slot:item="{ item, isMobile }"
              >
                <slot name="item" v-bind="{ item, isMobile }"></slot>
              </template>
              <!-- Create a slot within the slots for each field so we can access from the parent -->
              <template
                v-else
                v-for="field in fields"
                v-slot:[field.slotName]="{ item }"
              >
                <slot :name="field.value" v-bind:item="item">
                  {{ item[field.value] }}
                </slot>
              </template>

              <!-- Action slot for the button to go to the instance -->
              <template v-slot:item.actions="{ item }">
                <v-btn
                  icon
                  @click="
                    $router.push({
                      name: editView,
                      params: { id: item.id },
                    })
                  "
                  ><BaseIcon :type="iconType"
                /></v-btn>
              </template>
              <template slot="body.append">
                <slot name="body.append"></slot>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <TableFilters
      :dialog="searchDialog"
      @cancel="searchDialog = false"
      @submit="fetchData"
    >
      <template slot="body">
        <slot name="search-form"> </slot>
      </template>
    </TableFilters>
  </v-container>
</template>

<script>
//         @update:options="updatePagination"
import TableFilters from '@/components/tables/TableFilters.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'BaseCrudTable',
  components: { TableFilters },

  props: {
    editView: {
      required: false,
      type: String,
    },
    moduleName: {
      required: true,
      type: String,
    },
    headers: {
      required: true,
      type: Array,
    },
    simple: {
      required: false,
      default: false,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    showOptions: {
      type: Boolean,
      default: true,
    },
    showFilter: {
      type: Boolean,
      default: true,
    },
    showAppliedFilters: {
      type: Boolean,
      default: true,
    },
    search: {
      type: Boolean,
      default: true,
    },
    iconType: {
      type: String,
      default: 'edit',
    },
    title: {
      required: false,
    },
    allowPdf: {
      required: false,
      default: false,
    },
    allowCsv: {
      required: false,
      default: false,
    },
    disabled: {
      required: false,
      default: false,
    },
    quickSearch: {
      required: false,
      type: Object,
      default() {
        return { target: 'search', text: 'Quick Search' }
      },
    },
    flat: {
      // Whether to remove elevation from the card
      required: false,
      default: false,
    },
    hideTillSearch: {
      // Whether to hide the table until a search is performed
      required: false,
      default: false,
    },
    mobileBreakpoint: {
      // The breakpoint at which the table will switch to mobile view
      required: false,
      default: 768,
    },
  },

  data() {
    return {
      // fetching: false,
      supressFetch: true,
      searchDialog: false,
    }
  },

  methods: {
    fetchData() {
      // Display the loading indicator
      //this.fetching = true

      // If we are doing a new query then need to reset the pagination
      // back to page 1. We achieve this by passing the resetPagination parameter
      // to the vuex function. When the store resets the page number however
      // we need to ensure this component does not trigger another query on the
      // sync of the pagination object
      this.supressFetch = true

      this.$store
        .dispatch(`${this.moduleName}/fetchList`, { resetPagination: true })
        .then(() => {
          //this.fetching = false
          this.supressFetch = false
        })
    },
    setSearchParam(target, value) {
      this.$store.dispatch(`${this.moduleName}/setSearchParam`, {
        target,
        value,
      })
    },
    clearFilters() {
      //this.fetching = true
      this.supressFetch = true
      this.$store
        .dispatch(`${this.moduleName}/clearFilters`, { resetPagination: true })
        .then(() => {
          //this.fetching = false
          this.supressFetch = false
        })
    },
  },

  computed: {
    list() {
      let list = this.$store.getters[`${this.moduleName}/list`]
      return list ? list : []
    },
    searchParams() {
      return this.$store.getters[`${this.moduleName}/searchParams`]
    },
    currentFilters() {
      return this.$store.getters[`${this.moduleName}/currentFilters`]
    },
    fetching() {
      return this.$store.getters[`${this.moduleName}/fetching`]
    },
    hasFilters() {
      return Object.keys(this.currentFilters).length
    },
    hasSpecificFilters() {
      // Whether any targeted filters are applied and not just quick search
      if (
        !(this.quickSearch.target in this.currentFilters) &&
        this.hasFilters
      ) {
        return true
      } else {
        return false
      }
    },
    quickSearchText() {
      if (this.hasSpecificFilters) {
        return 'Please clear detailed filters to use quick search'
      } else {
        return this.quickSearch.text
      }
    },
    showTable() {
      if (this.hideTillSearch) {
        return this.hasFilters
      } else {
        return true
      }
    },
    ...mapGetters('downloads', ['activeDownloads']),
    options: {
      get() {
        return this.$store.getters[`${this.moduleName}/options`]
      },
      set(options) {
        // Table will set options on first load but we don't want to fetch on that
        // occassion. The firstFetch variable will then be used in vuex to ignore the
        // fetch call if required
        this.$store.dispatch(`${this.moduleName}/updatePagination`, {
          options: options,
          supressFetch: this.supressFetch,
        })
        this.supressFetch = false
      },
    },
    fields() {
      let fields = []
      for (let header of this.headers) {
        if (header.value != 'actions') {
          fields.push({
            slotName: `item.${header.value}`,
            value: header.value,
          })
        }
      }
      return fields
    },
    perPageOptions() {
      if (!this.showFooter) {
        return [-1]
      } else {
        return [5, 10, 15, 20]
      }
    },
  },
}
</script>

<style lang="scss">
.basecrud-table {
  &.v-data-table--mobile {
    // Additional padding when mobile to help align better with search bar etc
    padding: 0 5px;
  }
}

/* .table-crud-mobile td.v-data-table__mobile-row {
  padding: 5px 13px 5px 13px !important;
  min-height: 30px !important;
}

.table-crud-mobile tr.v-data-table__mobile-table-row {
  padding-top: 5px !important;
} */
/* .pagination-applied .v-data-footer {
  background-color: #8080802e;
  font-weight: bold;
} */
</style>

<style scoped>
.search-pointer {
  margin-top: -14px;
}
</style>
