import { apiMutations, apiActions, apiGetters } from '@/helpers/api.js'
import api from '@/services/axios.js'

export const namespaced = true

export const state = {
  endpoint: '/products',
  list: {
    fields: '',
    default: null,
    filtered: null,
    queryString: '',
    fetching: false,
    paginationOptions: {
      itemsPerPage: 10,
    },
    searchParams: {},
    currentFilters: {},
  },
  instance: {
    fields: '',
    data: null,
  },
  allProducts: [], // Used for invoice allocations
}

export const mutations = {
  ...apiMutations,
  SET_ALL_PRODUCTS(state, data) {
    state.allProducts = data
  },
}

export const actions = {
  ...apiActions,
  loadAllProducts({ state, commit }) {
    if (!state.allProducts.length) {
      api.get('/products/', { params: { limit: 1000 } }).then((res) => {
        commit('SET_ALL_PRODUCTS', res.data.results)
      })
    }
  },
}

export const getters = {
  ...apiGetters,
  baseProducts(state, getters, rootState, rootGetters) {
    let base = rootGetters['user/base']
    return state.allProducts.filter(
      (product) => !product.base || product.base == base.id
    )
  },
}
