<template>
  <div>
    <v-btn icon @click="dialog = true"
      ><BaseIcon type="delete" color="red darken-3"
    /></v-btn>
    <BaseDeleteDialog
      v-model="dialog"
      :disabled="disabled"
      @cancel="dialog = false"
      @delete="deleteInstance"
    />
  </div>
</template>

<script>
import api from '@/services/axios'
import { notifyErrors } from '@/helpers/crud'

export default {
  name: 'BaseDeleteInstance',

  props: {
    moduleName: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      dialog: false,
      disabled: false,
    }
  },

  methods: {
    deleteInstance() {
      this.disabled = true
      this.$NProgress.start()
      api
        .delete(this.deleteEndpoint)
        .then(() => {
          this.$emit('deleted')
        })
        .catch((error) => {
          this.$NProgress.done()
          this.disabled = false
          this.dialog = false
          notifyErrors(error)
        })
    },
  },

  computed: {
    deleteEndpoint() {
      return this.$store.getters[`${this.moduleName}/deleteEndpoint`]
    },
  },
}
</script>

<style></style>
