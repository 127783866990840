<template>
  <!-- <div class="notification-bar" :class="notificationTypeClass">
    <p>{{ notification.message }}</p>
  </div> -->
  <v-alert :type="notification.type" class="notification-bar" dismissible dense>
    {{ notification.message }}
  </v-alert>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      timeout: null,
    }
  },
  mounted() {
    this.timeout = setTimeout(() => this.remove(this.notification), 5000)
  },
  beforeDestroy() {
    clearTimeout(this.timeout)
  },

  methods: mapActions('notification', ['remove']),
}
</script>

<style scoped>
.notification-bar {
  margin: 0 auto;
  max-width: 400px;
}
</style>
