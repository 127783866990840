import store from '@/store'

const aircraftRoutes = [
  {
    path: '/aircraft',
    name: 'Aircraft',
    meta: { permissions: [], linkTag: 'admin' },
    component: () =>
      import(
        /* webpackChunkName: "members" */ '@/views/admin/aircraft/AircraftList.vue'
      ),
    beforeEnter(routeTo, routeFrom, next) {
      store
        .dispatch(`aircraft/loadList`, { params: routeTo.params })
        .then(() => next())
    },
  },
  {
    path: '/aircraft/:id',
    name: 'AircraftDetail',
    meta: { parent: 'aircraft', permissions: [], linkTag: 'admin' },
    props: { edit: true },
    component: () =>
      import(
        /* webpackChunkName: "aircraft" */ '@/views/admin/aircraft/AircraftDetail.vue'
      ),
    beforeEnter(routeTo, routeFrom, next) {
      store
        .dispatch(`aircraft/loadInstance`, { params: routeTo.params })
        .then(() => next())
    },
  },
  {
    path: '/app-admin/aircraft/add',
    name: 'AircraftAdminAdd',
    meta: { parent: 'Admin', permissions: [], linkTag: 'admin' },
    component: () =>
      import(
        /* webpackChunkName: "flight-types" */ '@/views/admin/aircraft/AircraftAdd.vue'
      ),
  },
]

export default aircraftRoutes
