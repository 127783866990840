<template>
  <div>
    <div
      v-if="labelAbove"
      class="label-above"
      :class="{ 'error--text': errorMessages.length }"
    >
      {{ $attrs.label }}
    </div>
    <v-select
      v-bind="attrs"
      v-on="listeners"
      :error-messages="errorMessages"
      :outlined="outlined"
      :hide-details="hideDetails"
      @keydown="tabNext"
      :label="labelAbove ? null : $attrs.label"
    >
      <template v-slot:append-item>
        <slot name="append-item"></slot>
      </template>
    </v-select>
  </div>
</template>

<script>
import { tabNext, syncMixin } from '@/helpers/components.js'
export default {
  name: 'BaseSelect',
  mixins: [syncMixin],

  data() {
    return {
      tabNext, // Correctly sequences tab behaviour
    }
  },
  props: {
    errors: {
      required: false,
      default() {
        return []
      },
    },
    outlined: {
      required: false,
      default: true,
    },
    hideDetails: {
      required: false,
      default: 'auto',
    },
    labelAbove: {
      required: false,
      type: Boolean,
    },
  },

  created() {
    this.syncItems(this.attrs.items)
  },

  methods: {
    // syncItems() {
    //   // In case the existing value isn't part of the list we need to add it
    //   let value = this.$attrs.value
    //   // Search items to see if matching object found
    //   if (value && typeof value == 'object') {
    //     let found = this.$attrs.items.find((e) => e.id == value.id)
    //     if (!found) {
    //       this.$attrs.items.push(value)
    //     }
    //   }
    // },
  },

  computed: {
    listeners() {
      return {
        ...this.$listeners,
      }
    },
    attrs() {
      return {
        ...this.$attrs,
        returnObject: this.$attrs.returnObject
          ? this.$attrs.returnObject
          : true,
      }
    },
    errorMessages() {
      if (this.errors) {
        return this.errors.map((error) => error.$message)
      } else {
        return []
      }
    },
    // items() {
    //   //console.log(this.$attrs.items)
    //   return this.$attrs.items
    //   //this.syncItems()
    //   //return this.$attrs.items.concat(this.extraItems)
    // },
  },
}
</script>

<style>
.label-above {
  font-size: 1em;
  margin-bottom: 2px;
}
</style>
