<template>
  <v-file-input
    v-bind="$attrs"
    v-on="$listeners"
    :error-messages="errorMessages"
  ></v-file-input>
</template>

<script>
export default {
  name: 'BaseFileInput',
  props: ['errors'],

  methods: {},

  computed: {
    errorMessages() {
      if (this.errors) {
        return this.errors.map((error) => error.$message)
      } else {
        return []
      }
    },
  },
}
</script>
