import api from '@/services/axios'
import { DateTime } from 'luxon'

export const namespaced = true

export const state = {
  voucher: null,
  selectedBase: null,
  selectedVoucherType: null,
  selectedSlot: null,
  voucherQuestions: [],
  voucherQuestionFormID: null,
  rebook: false,
  slotTaken: false,
  appliedAddOns: [],
}

export const mutations = {
  SET_VOUCHER(state, voucher) {
    state.voucher = voucher
  },
  CLEAR_STORE(state) {
    state.voucher = null
    state.selectedBase = null
    state.selectedVoucherType = null
    state.selectedSlot = null
    state.appliedAddOns = []
    state.rebook = false
    state.slotTaken = false
  },
}

export const actions = {
  storeVoucher({ commit, state, dispatch }, voucher) {
    // Directly commit the mutation to clear the store
    commit('CLEAR_STORE')

    // Commit the voucher and set the voucher type
    commit('SET_VOUCHER', voucher)
    state.selectedVoucherType = voucher.voucher_type

    // Dispatch the resetAppliedAddOns action and return its promise
    return dispatch('resetAppliedAddOns')
  },
  setBase({ state }, base) {
    state.selectedBase = base
  },
  setVoucherType({ state }, type) {
    state.selectedVoucherType = type
  },
  setSelectedSlot({ state }, slot) {
    state.selectedSlot = slot
    state.slotTaken = false
  },
  clearStore({ commit }) {
    return commit('CLEAR_STORE')
  },
  getVoucherQuestions({ state }) {
    // Determine the form we need
    // Assume we are booking if a selected slot exists
    let formID = null
    if (state.selectedSlot) {
      formID = state.selectedVoucherType.booking_form
    } else {
      formID = state.selectedVoucherType.purchase_form
    }

    // If no form then return and set the questions to empty
    if (!formID) {
      state.voucherQuestions = []
      state.voucherQuestionFormID = null
      return
    }

    // If we already have the form then return to prevent resetting
    // the intake form
    if (state.voucherQuestionFormID === formID) {
      return
    }

    return api.get(`/form_questions?form=${formID}`).then((response) => {
      state.voucherQuestions = response.data.results
      state.voucherQuestionFormID = formID
      return
    })
  },
  validateJwt({ dispatch }, jwt) {
    return api.post('vouchers/jwt_validate/', { jwt }).then((res) => {
      // Store the voucher in the store and wait for it to complete
      return dispatch('storeVoucher', res.data).then(() => {
        return res
      })
    })
  },
  rebook({ state }, bool) {
    state.rebook = bool
    state.selectedSlot = null
  },
  slotTaken({ state, dispatch }) {
    state.slotTaken = true
    dispatch(
      'notification/add',
      {
        message:
          'Sorry, your selected slot is no longer available. Please select another slot.',
        type: 'warning',
      },
      { root: true }
    )
  },
  toggleAddOn({ state }, item) {
    if (!item) return
    let index = state.appliedAddOns.findIndex((a) => a.id === item.id)
    if (index === -1) {
      state.appliedAddOns.push(item)
    } else {
      state.appliedAddOns.splice(index, 1)
    }
  },
  resetAppliedAddOns({ state, getters }) {
    state.appliedAddOns = []
    // Add the existing add-ons if we have a voucher
    if (state.voucher) {
      for (let item of state.voucher.applied_add_ons) {
        // Find the item in the list of available add-ons
        let addOn = getters.availableAddOns.find((a) => a.id === item.add_on)

        // If found add it to the list of applied add-ons
        if (addOn) {
          state.appliedAddOns.push(addOn)
        } else {
          // Create a pseudo add-on so we can display it
          state.appliedAddOns.push({
            id: item.add_on,
            name: item.description,
            product: {
              price: 0,
            },
          })
        }
      }
    }
  },
}

export const getters = {
  voucher() {
    return state.voucher
  },
  selectedBase() {
    return state.selectedBase
  },
  selectedVoucherType() {
    return state.selectedVoucherType
  },
  selectedSlot() {
    return state.selectedSlot
  },
  voucherQuestions() {
    return state.voucherQuestions
  },
  bookedSlotStr() {
    let res = {
      date: '',
      time: '',
    }
    if (state.voucher.booking) {
      // Format Tuesday, 1 September 2024
      let date = DateTime.fromISO(state.voucher.booking.start)
      res.date = date.toFormat('cccc, d LLLL yyyy')
      res.time = date.toFormat('HH:mm')
    }
    return res
  },
  rebookingMode() {
    return state.rebook
  },
  slotTaken() {
    return state.slotTaken
  },
  availableAddOns() {
    if (!state.selectedVoucherType) {
      return []
    } else if (state.voucher) {
      // If we have a voucher the upgrades are based on the purchased_type
      return state.voucher.purchased_type.available_add_ons
    } else {
      // For fresh purchases can use the upgrades on the selected type
      return state.selectedVoucherType.available_add_ons
    }
  },
  appliedAddOns() {
    return state.appliedAddOns
  },
  existingAddOnIds() {
    // List of existing add-ons if we have a voucher
    if (state.voucher) {
      return state.voucher.applied_add_ons.map((a) => a.add_on)
    } else {
      return []
    }
  },
  appliedAddOnsString() {
    return state.appliedAddOns.map((a) => a.name).join(', ')
  },
  basketTotal: (state, getters) => {
    let total = 0
    if (state.voucher) {
      // TODO - Only interested in the upgrade cost
    } else if (state.selectedVoucherType) {
      // Add the voucher cost
      total = total + Number(state.selectedVoucherType.price * 100)
    }

    if (state.appliedAddOns) {
      state.appliedAddOns.forEach((addOn) => {
        // Only interested in those which arent existing
        if (!getters.existingAddOnIds.includes(addOn.id)) {
          total = total + Number(addOn.product.price * 100)
        }
      })
    }

    // Convert to pounds
    total = total / 100
    return total
  },
  cardExpand() {
    return state.selectedSlot ? false : true
  },
}
