import { apiMutations, apiActions, apiGetters } from '@/helpers/api.js'

export const namespaced = true

export const state = {
  endpoint: '/courses',
  list: {
    fields: '{id, name}',
    default: null,
    filtered: null,
    paginationOptions: {
      itemsPerPage: 100,
    },
    searchParams: {},
    currentFilters: {},
  },
  instance: {
    data: null,
  },
}

export const mutations = {
  ...apiMutations,
}

export const actions = {
  ...apiActions,
}

export const getters = {
  ...apiGetters,
}
