<template>
  <div class="base-label"><slot></slot></div>
</template>

<script>
export default {
  name: 'BaseLabel',
}
</script>

<style scoped>
.base-label {
  margin-bottom: 4px;
}
</style>
