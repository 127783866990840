<template>
  <div class="height-100">
    <ErrorBlock icon="mdi-server" title="500 Internal Server Error :(">
      <!-- <template slot>
        <p class="text-center">
          Oops... looks like the server has crashed! We've notified the tech
          people so hopefully they'll be able to look into this and get it fixed
          soon.
        </p>
      </template> -->
    </ErrorBlock>
  </div>
</template>

<script>
import ErrorBlock from '@/components/app/ErrorBlock'

export default {
  name: 'ServerError',
  components: { ErrorBlock },

  data() {
    return {}
  },

  methods: {},
}
</script>

<style scoped></style>
