import { apiMutations, apiActions, apiGetters } from '@/helpers/api.js'
import api from '@/services/axios.js'

export const namespaced = true

export const state = {
  endpoint: '/users',
  list: {
    fields: '{id, first_name, last_name, email, phone}',
    default: null,
    filtered: null,
    fetching: false,
    paginationOptions: {
      itemsPerPage: 10,
    },
    searchParams: {
      search: {
        label: 'Search',
        value: null,
      },
      first_name__icontains: {
        label: 'First Name',
        value: null,
      },
      last_name__icontains: {
        label: 'Last Name',
        value: null,
      },
      email__icontains: {
        label: 'Email',
        value: null,
      },
    },
    currentFilters: {},
  },
  instance: {
    fields: '',
    data: null,
  },
  allUsers: [], // Used for autocompletes,
}

export const mutations = {
  ...apiMutations,
  SET_INSTANCE_DATA(state, { field, value }) {
    state.instance.data[field] = value
  },
  SET_ALL_USERS(state, list) {
    state.allUsers = list
  },
}

export const actions = {
  ...apiActions,
  setField({ commit }, { field, value }) {
    commit('SET_INSTANCE_DATA', { field, value })
  },
  fetchAllUsers({ commit, state }) {
    // Don't fetch if we already have the users
    if (state.allUsers.length) {
      return
    }

    // Give the list 1 psuedo element to ensure we dont fire multiple requests
    // simultaneously
    commit('SET_ALL_USERS', [
      {
        id: 1,
        first_name: 'Loading',
        last_name: 'Loading',
        short_name: 'Loading',
      },
    ])

    return api
      .get(
        '/users.json?limit=90000&query={id, first_name, last_name, short_name, full_name}&active=true'
      )
      .then((res) => {
        commit('SET_ALL_USERS', res.data.results)
        return
      })
  },
}

export const getters = {
  ...apiGetters,
  allUsers(state) {
    return state.allUsers
  },
}
