<template>
  <v-text-field
    v-bind="$attrs"
    v-on="listeners"
    :error-messages="errorMessages"
    @blur="onBlur"
    @keydown.enter="onBlur"
    :hide-details="hideDetails"
    :value="displayValue"
    outlined
  >
  </v-text-field>
</template>

<script>
export default {
  inheritAttrs: false,

  data() {
    return {
      inputErrors: [],
    }
  },
  props: {
    errors: {
      required: false,
      default: null,
    },
    hideDetails: {
      required: false,
      default: 'auto',
    },
  },
  methods: {
    updateValue() {
      // Do nothing on input as we want to emit only on blur
      return
    },
    onBlur(event) {
      let value = event.target.value
      // If no input then just emit blank and don't validate
      if (value == '') {
        this.$emit('input', value)
        return
      }

      if (!value.includes('.')) {
        value = value + '.00'
      }
      // Add trailing zero if only one decimal place provided
      if (/\.\d{1}$/.test(value)) {
        value = value + '0'
      }

      // Validate in form hh.mm
      if (!/^\d+\.\d{2}$/.test(value)) {
        this.inputErrors.push('hh.mm (Decimal)')
        return
      } else {
        // Clear errors if validation passes
        this.inputErrors = []
      }
      // Cast to decimal
      value = parseFloat(value)
      this.$emit('input', value)
    },
  },

  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue, // Call a custom input handler
      }
    },
    errorMessages() {
      if (this.errors) {
        let vuelidate = this.errors.map((error) => error.$message)
        return this.inputErrors.concat(vuelidate)
      } else {
        return this.inputErrors
      }
    },
    displayValue() {
      if (this.$attrs.value) {
        return this.$attrs.value.toFixed(2)
      } else {
        return null
      }
    },
  },
}
</script>

<style></style>
