<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-container>
        <v-form @submit.prevent>
          <v-card-title>
            <div class="d-flex w-100">
              <div class="text-h5">Filters</div>
              <!-- <v-btn icon class="ml-auto" @click="$emit('reset')"
                ><v-icon>mdi-refresh</v-icon></v-btn
              > -->
            </div>
          </v-card-title>
          <v-card-text class="mt-3">
            <slot name="body"> </slot>
          </v-card-text>
          <v-card-actions class="mt-3">
            <v-btn color="blue darken-1" text @click="close" tabindex="-1">
              Close
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              type="submit"
              color="primary"
              :disabled="disabled"
              @click="applyFilters"
            >
              Search
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'TableFilters',
  data() {
    return {
      disabled: false,
    }
  },
  props: {
    dialog: {
      required: true,
    },
  },

  methods: {
    close() {
      this.$emit('cancel')
    },
    applyFilters() {
      this.close()
      this.$emit('submit')
    },
  },
}
</script>

<style></style>
