<template>
  <div class="snack-container">
    <v-snackbar
      v-for="(value, key) in pending"
      :key="`pending_${key}`"
      :timeout="-1"
      :value="true"
      color="blue-grey"
      rounded="pill"
      class="snack"
    >
      <div class="d-flex align-center">
        <div class="font-weight-bold">
          Exporting: {{ value.filename }}
          <span v-if="value.message">({{ value.message }})</span>
        </div>
        <v-progress-circular
          class="ml-auto"
          indeterminate
          color="white"
        ></v-progress-circular>
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {},
  props: {},
  computed: {
    containerStyle() {
      if (this.navDrawer) {
        return {
          width: 'calc(100% - 256px)',
          marginLeft: '256px',
        }
      } else {
        return {
          width: '100%',
        }
      }
    },
    ...mapGetters('downloads', ['pending']),
  },
}
</script>

<style scoped>
.snack-container {
  position: fixed;
  bottom: 10px;
  right: 0;
  padding-right: 13px;
}

.snack {
  height: auto !important;
  padding-top: 0px !important;
  position: relative !important;
}
</style>
