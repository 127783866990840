<template>
  <v-autocomplete
    v-bind="attrs"
    v-on="listeners"
    :outlined="outlined"
    :hide-details="hideDetails"
    :error-messages="errorMessages"
    @keydown="tabNext"
    auto-select-first
  >
    <template v-slot:label>
      <slot name="label" v-if="$slots.label">{{ $attrs.label }}</slot>
    </template>
  </v-autocomplete>
</template>

<script>
import { tabNext, syncMixin } from '@/helpers/components.js'

export default {
  name: 'BaseAutocomplete',
  mixins: [syncMixin],

  props: {
    errors: {
      required: false,
      default() {
        return []
      },
    },
    outlined: {
      required: false,
      default: true,
    },
    hideDetails: {
      required: false,
      default: 'auto',
    },
  },

  created() {
    this.syncItems(this.attrs.items)
  },

  data() {
    return {
      // extraItems: [],
      tabNext, // Correctly sequences tab behaviour
    }
  },

  methods: {
    // syncItems() {
    //   // In case the existing value isn't part of the list we need to add it
    //   let value = this.$attrs.value
    //   let items = this.$attrs.items
    //   console.log(value)
    //   // Search items to see if matching object found
    //   if (value) {
    //     let found = items.find((e) => e.id == value.id)
    //     if (!found) {
    //       this.extraItems.push(value)
    //     }
    //     if (this.attrs['return-object'] == false && typeof value != 'number') {
    //       // If we are not returning an object then emit the value
    //       // to trigger flattening to ID when the component loads
    //       this.$emit('input', value.id)
    //     }
    //   }
    // },
  },

  computed: {
    listeners() {
      return {
        ...this.$listeners,
      }
    },
    attrs() {
      return {
        ...this.$attrs,
        'return-object':
          'return-object' in this.$attrs ? this.$attrs['return-object'] : true,
      }
    },
    errorMessages() {
      if (this.errors) {
        return this.errors.map((error) => error.$message)
      } else {
        return []
      }
    },
    // items() {
    //   return this.$attrs.items.concat(this.extraItems)
    // },
  },
}
</script>
