import store from '@/store'

const recordsRoutes = [
  {
    path: '/records',
    name: 'StudentRecords',
    meta: { permissions: [], parent: 'StudentRecords', linkTag: 'records' },
    component: () =>
      import(
        /* webpackChunkName: "records" */ '@/views/records/StudentRecordsHome.vue'
      ),
    beforeEnter(routeTo, routeFrom, next) {
      const outstandingRecords = store.dispatch(
        `records/fetchOutstandingRecords`
      )
      const instructorDrafts = store.dispatch(`records/fetchInstructorDrafts`)
      Promise.all([outstandingRecords, instructorDrafts]).then(() => next())
    },
  },
  {
    path: '/course/:id',
    name: 'CourseOverview',
    meta: { permissions: [], parent: 'StudentRecords', linkTag: 'records' },
    component: () =>
      import(
        /* webpackChunkName: "records" */ '@/views/records/CourseOverview.vue'
      ),
    beforeEnter(routeTo, routeFrom, next) {
      store
        .dispatch(`records/loadCourseData`, { params: routeTo.params })
        .then(() => next())
    },
  },
  {
    path: '/record/:id',
    name: 'StudentRecord',
    meta: { permissions: [], parent: 'StudentRecords', linkTag: 'records' },
    component: () =>
      import(
        /* webpackChunkName: "records" */ '@/views/records/StudentRecord.vue'
      ),
    beforeEnter(routeTo, routeFrom, next) {
      store
        .dispatch(`records/loadInstance`, { params: routeTo.params })
        .then(() => {
          // After getting the report data need to make sure the course structure
          // is loaded too
          let instance = store.getters['records/instance']
          const coursePromise = store.dispatch('records/loadCourseData', {
            params: { id: instance.course_subscription.id },
          })
          const outstandingRecords = store.dispatch(
            'records/loadOutstandingRecords'
          )
          // Wait for all data to be loaded
          Promise.all([coursePromise, outstandingRecords]).then(() => next())
        })
    },
  },
  {
    path: '/user-records',
    name: 'StudentIndex',
    meta: { permissions: [], linkTag: 'records' },
    component: () =>
      import(
        /* webpackChunkName: "records" */ '@/views/records/StudentIndex.vue'
      ),
  },
]

export default recordsRoutes
