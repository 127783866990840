<template>
  <v-icon v-bind="$attrs">{{ icons[type] }}</v-icon>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    type: {
      required: true,
    },
  },
  data() {
    return {
      icons: {
        edit: 'mdi-pencil',
        delete: 'mdi-delete',
        open: 'mdi-open-in-new',
      },
    }
  },
}
</script>

<style></style>
