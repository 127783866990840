import { DateTime, Duration } from 'luxon'

export function getSubdomain() {
  // Get the part of the host before the first dot
  let host_start = window.location.hostname.split('.')[0]
  if (host_start == 'flightcloud') {
    return null
  } else {
    return host_start
  }
}
export function localDateTime(isoDateTime) {
  let dt = new Date(isoDateTime)
  let str = dt.toLocaleString('en-GB').slice(0, -3)
  return str.replace(',', '')
}

export function formatDate(isoDate) {
  if (!isoDate) return null
  const [year, month, day] = isoDate.split('-')
  return `${day}/${month}/${year}`
}

export function fullAddress(addressJson) {
  // Return null if the address doesn't exists as an object
  if (!addressJson) {
    return ''
  }

  let result = []

  for (let field in addressJson) {
    if (field == 'tenant' || field == 'id' || field == 'country') {
      continue
    } else if (addressJson[field]) {
      result.push(addressJson[field])
    }
  }

  return result.join('\r\n')
}

export function daysElapsed(dateIso) {
  let start = DateTime.fromISO(dateIso)
  let end = DateTime.utc()
  let days = Math.floor(end.diff(start).as('days'))
  return days
}

export function todaysDate() {
  return DateTime.utc().toISODate()
}

export function localTime(dateISO) {
  let dt = DateTime.fromISO(dateISO)
  return dt.toLocaleString(DateTime.TIME_24_SIMPLE)
}

export function durationToSeconds(duration) {
  var hms = duration // your input string
  var a = hms.split(':') // split it at the colons

  // Add seconds if not supplied
  if (a.length == 2) {
    a.push('00')
  }

  // minutes are worth 60 seconds. Hours are worth 60 minutes.
  var seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2]
  return seconds
}

export function parseErrors(vuelidateErrors) {
  if (vuelidateErrors) {
    return vuelidateErrors.map((error) => error.$message)
  } else {
    return []
  }
}

export function totalDuration(startISO, endISO) {
  let start = DateTime.fromISO(startISO)
  let end = DateTime.fromISO(endISO)
  if (start.isValid && end.isValid) {
    let diff = end.diff(start, ['hours', 'minutes']).toObject()
    return diff.hours + ':' + diff['minutes'].toString().padStart(2, '0')
  } else {
    return '0:00'
  }
}

export function datetime(iso, is_international = false) {
  let dt = DateTime.fromISO(iso)
  if (is_international) {
    return {
      date: dt.setZone('utc').toLocaleString(DateTime.DATE_SHORT),
      time: dt.setZone('utc').toLocaleString(DateTime.TIME_24_SIMPLE) + 'z',
    }
  } else {
    return {
      date: dt.setLocale('gb').toLocaleString(DateTime.DATE_SHORT),
      time: dt.setLocale('gb').toLocaleString(DateTime.TIME_24_SIMPLE),
    }
  }
}

export function durationDelta(start, end) {
  // Difference between two durations (hhh:mm)
  end = Duration.fromObject({ seconds: durationToSeconds(end) })
  start = Duration.fromObject({ seconds: durationToSeconds(start) })
  let diff = Duration.fromObject({ milliseconds: end - start }).shiftTo(
    'hours',
    'minutes'
  )
  return diff.hours + ':' + diff.minutes.toString().padStart(2, '0')
}

export function durationSum(durationList) {
  // Sum of durations (hhh:mm)
  let total = 0
  for (let duration of durationList) {
    total += durationToSeconds(duration)
  }
  // Output in hhh:mm
  let duration = Duration.fromObject({ seconds: total }).shiftTo(
    'hours',
    'minutes'
  )
  return duration.hours + ':' + duration.minutes.toString().padStart(2, '0')
}

export function isExpired(dateISO) {
  return daysElapsed(dateISO) > 0
}

export function openLink(path) {
  // Given a path go open a new window using the 8000 port
  let origin = window.location.origin
  origin = origin.replace('8080', '8000')
  return window.open(origin + path)
}

export function formatPence(pence) {
  let absolute = Math.abs(pence / 100).toFixed(2)
  if (pence >= 0) {
    return `£${absolute}`
  } else {
    return `-£${absolute}`
  }
}

export function getAge(dateISO) {
  let dt = DateTime.fromISO(dateISO)
  let now = DateTime.utc()
  return now.diff(dt, 'years').years
}

export function hhmm_to_decimal(hhmm) {
  let [hours, minutes] = hhmm.split(':')
  // Convert minutes to decimal
  minutes = minutes / 60
  // Multiply by 100 to get 2 decimal places
  minutes = minutes * 100
  // Round to 2 decimal places
  minutes = Math.round(minutes)
  // Ensure trailing zeros added if needed
  minutes = minutes.toString().padStart(2, '0')
  return hours + '.' + minutes
}
