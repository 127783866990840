import { apiMutations, apiActions, apiGetters } from '@/helpers/api.js'
import api from '@/services/axios.js'

export const namespaced = true

export const state = {
  endpoint: '/notices',
  list: {
    fields: '',
    default: null,
    filtered: null,
    paginationOptions: {
      itemsPerPage: 10,
    },
    searchParams: {
      search: {
        label: 'Search',
        value: null,
      },
      aircraft: {
        label: 'Aircraft',
        value: null,
      },
    },
    currentFilters: {},
  },
  instance: {
    fields: '',
    data: null,
  },
  userNotices: {
    outstanding: null,
    notices: [],
    selectedNotice: null,
  },
}

export const mutations = {
  ...apiMutations,
  SET_USER_NOTICES(state, data) {
    state.userNotices.notices = data.notices
    state.userNotices.outstanding = data.outstanding
  },
  SET_SELECTED_NOTICE(state, noticeId) {
    // Find the notice in the list
    const notice = state.userNotices.notices.find(
      (notice) => notice.id == noticeId
    )
    state.userNotices.selectedNotice = notice
  },
}

export const actions = {
  ...apiActions,
  // Get the notices for the current user
  fetchUserNotices({ commit, rootState }) {
    // Get user id from rootState
    const userId = rootState.user.user.id
    return api.get(`/users/${userId}/notices/`).then((response) => {
      commit('SET_USER_NOTICES', response.data)
    })
  },
  viewNotice({ commit, dispatch, state }, noticeId) {
    // If we haven't got any notices loaded then fetch them first
    if (!state.userNotices.notices.length) {
      return dispatch('fetchUserNotices').then(() => {
        commit('SET_SELECTED_NOTICE', noticeId)
      })
    } else {
      commit('SET_SELECTED_NOTICE', noticeId)
      return
    }
  },
}

export const getters = {
  ...apiGetters,
  userNotices: (state) => state.userNotices,
}
