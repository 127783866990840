export function tabNext($event) {
  // Default behaviour seems to be that after selecting a item from the
  // dropdown using a mouse click, the tab order will return to the start
  // This fix ensures the next tab item as per dom order is followed

  if ($event.key == 'Tab') {
    $event.preventDefault()

    //Isolate the node that we're after
    const currentNode = $event.target

    // Use settimeout to allow DOM to update in case field visibility changes
    // based on what as just been selected

    setTimeout(function () {
      //find all tab-able elements
      let allElements = document.querySelectorAll(
        'input:not([type="hidden"]), button:not(.mdi-close), a, area, object, select, textarea, [contenteditable]'
      )

      // Filter any with tabindex zero
      allElements = [...allElements].filter((item) => item.tabIndex != -1)

      //Find the current tab index.
      const currentIndex = allElements.findIndex((el) =>
        currentNode.isEqualNode(el)
      )

      //focus the following element
      const targetIndex = (currentIndex + 1) % allElements.length
      allElements[targetIndex].focus()
    }, 1)
  }
}

export const syncMixin = {
  methods: {
    syncItems(items) {
      // In case the existing value isn't part of the list we need to add it
      let value = this.$attrs.value

      // Search items to see if matching object found
      if (value && typeof value == 'object') {
        let found = items.find((e) => e.id == value.id)
        if (!found) {
          items.push(value)
        }
        if (this.attrs['return-object'] == false && typeof value == 'object') {
          // If we are not returning an object then emit the value
          // to trigger flattening to ID when the component loads
          this.$emit('input', value.id)
        }
      }
      // If the value given is an ID and we are returning an object
      // then we need to find the object and emit it
      if (typeof value == 'number' && this.attrs.returnObject == true) {
        let found = items.find((e) => e.id == value)

        if (found) {
          this.$emit('input', found)
        }
      }
    },
  },
}
