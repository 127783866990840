<template>
  <div class="height-100">
    <ErrorBlock icon="mdi-help-rhombus" title="404 Not Found :(">
      <template slot>
        <p class="text-center">
          Sorry we can't find what you're looking for! Doesn't look like this
          page exists...
        </p>
      </template>
    </ErrorBlock>
  </div>
</template>

<script>
import ErrorBlock from '@/components/app/ErrorBlock'

export default {
  name: 'NotFound',
  components: { ErrorBlock },

  data() {
    return {}
  },

  methods: {},
}
</script>

<style scoped></style>
