<template>
  <!-- <v-text-field label="Duration" hide-details outlined @focus="test">
    <template v-slot:append>
      <div class="d-flex align-center">
        <input type="text" class="duration-input" id="test" />
        <span>:</span>
        <input type="number" min="0" max="59" class="duration-input" />
      </div>
    </template>
  </v-text-field> -->
  <div
    class="v-input v-input--hide-details v-input--is-focused theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined primary--text"
  >
    <div class="v-input__control">
      <div class="v-input__slot">
        <fieldset aria-hidden="true"></fieldset>
        <div class="v-text-field__slot">
          <label
            for="input-311"
            class="v-label theme--light"
            :class="{ 'v-label--active': focused }"
            style="left: 0px; right: auto; position: absolute"
            >Intended Duration</label
          >
          <div class="d-flex">
            <input
              @focus="focused = true"
              @blur="focused = false"
              autocomplete="new-entry"
              id="input-311"
            />
            <input
              @focus="focused = true"
              @blur="focused = false"
              autocomplete="new-entry"
              id="input-311"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <v-text-field
    v-bind="$attrs"
    v-on="listeners"
    :error-messages="errorMessages"
    :autocomplete="autocomplete"
    @blur="onBlur"
    @keydown.enter="onBlur"
    :hide-details="hideDetails"
    outlined
  >
  </v-text-field> -->
</template>

<script>
export default {
  inheritAttrs: false,

  data() {
    return {
      inputErrors: [],
      focused: false,
    }
  },
  props: {
    errors: {
      required: false,
      default: null,
    },
    autocomplete: {
      type: String,
      required: false,
      default: 'new-nope',
    },
    hideDetails: {
      required: false,
      default: 'auto',
    },
  },
  methods: {
    test() {
      let i = document.getElementById('test')
      setTimeout(() => {
        i.focus()
      }, 100)
    },
    updateValue() {
      // Do nothing on input as we want to emit only on blur
      return
    },
    onBlur(event) {
      let value = event.target.value
      // If no input then just emit blank and don't validate
      if (value == '') {
        this.$emit('input', value)
        return
      }
      // Auto enter colon if not provided
      if (!value.includes(':')) {
        value = value + ':00'
      }

      // Fill in leading zero if not provided
      if (/:\d{1}$/.test(value)) {
        let last_digit = value[value.length - 1]
        value = value.slice(0, -1) + '0' + last_digit
      }
      // Validate in form hh:mm
      if (!/^\d+:[0-5][0-9]$/.test(value)) {
        this.inputErrors.push('h:mm')
        return
      } else {
        // Clear errors if validation passes
        this.inputErrors = []
      }

      this.$emit('input', value)
    },
  },

  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue, // Call a custom input handler
      }
    },
    errorMessages() {
      if (this.errors) {
        let vuelidate = this.errors.map((error) => error.$message)
        return this.inputErrors.concat(vuelidate)
      } else {
        return this.inputErrors
      }
    },
  },
}
</script>

<style></style>
