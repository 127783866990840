<template>
  <div>
    <v-text-field
      v-if="type == 'time'"
      v-bind="$attrs"
      v-on="listeners"
      :value="dt.time"
      outlined
      @blur="emitTime"
      :error-messages="errorMessages"
      @input="updateTime"
      hide-details="auto"
    >
    </v-text-field>
    <BaseDateInput
      v-else
      :label="$attrs.label"
      :value="dt.date"
      :canClear="false"
      @input="updateDate"
    />
  </div>
</template>

<script>
import { DateTime } from 'luxon'

export default {
  data() {
    return {
      timeErrors: [],
      dt: {
        date: '',
        time: '',
      },
    }
  },

  props: {
    date: {
      type: String,
      required: false,
    },
    value: {
      required: true,
    },
    type: {
      // Pass in either 'time' or 'date'
      required: true,
      type: String,
    },
    errors: {},
    utc: {
      required: false,
      default: true,
      type: Boolean,
    },
  },

  created() {
    // On component create, sync the value to our component
    this.syncValue()

    // If we are supplying the date from another field then sync that too
    if (this.date) {
      this.syncDate()
    }
  },

  watch: {
    // Additionally sync whenever the value is updated
    value() {
      this.syncValue()
    },
    date() {
      this.syncDate()
    },
    utc() {
      this.syncValue()
    },
  },

  methods: {
    supressDefaultInput() {
      // Override the default input handler and just do nothing
      // as we only want to update when we change focus
      return
    },
    syncValue() {
      // This method is called whenever the value prop passed to this component is updated
      // It results in updating the dt object that is used to render the correct
      // values in the date picker or time picker

      this.dt.date = this.date
      this.dt.time = ''

      if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/.test(this.value)) {
        // If passes this regex our input is in the form of a ISO string
        let datetime = DateTime.fromISO(this.value)
        if (this.utc) {
          this.dt.date = datetime.setZone('utc').toFormat('yyyy-MM-dd')
          this.dt.time = datetime.setZone('utc').toFormat('HH:mm') + 'z'
          return
        } else {
          this.dt.date = datetime
            .setZone('Europe/London')
            .toFormat('yyyy-MM-dd')
          this.dt.time = datetime.setZone('Europe/London').toFormat('HH:mm')
          return
        }
      }

      // If we haven't returned by this point we are not dealing with an ISO valid string
      // Just add the string values for date and / or time
      let str = this.value

      if (!this.value) {
        // Deal with case where an undefined datetime is passed
        // Need a string in order for match to work below
        str = ''
      }

      let dateStr = str.match(/^\d{4}-\d{2}-\d{2}/)
      let timeStr = str.match(/\d{2}:\d{2}z?/)

      if (dateStr) {
        this.dt.date = dateStr[0]
      }
      if (timeStr) {
        this.dt.time = timeStr[0]
      }
    },
    syncDate() {
      // If we are manually supplying the date from another field then update with it's value
      // When we first load the component we don't want to emit though if the supplied date matches
      // what we already extracted from the field value in syncValue()
      if (this.dt.date != this.date) {
        this.dt.date = this.date
        this.emitDate() // Act as if we had just changed the date using the datepicker
      }
    },
    validateTime(value) {
      if (value.length == 0) {
        return value
      } else if (value.search(/^\d{2}:\d{2}$/) == 0) {
        // If of hh:mm can return straight away
        return value
      } else if (value.search(/^\d{4}$/) == 0) {
        // Autocorrect if 4 digits without colon
        return value.slice(0, 2) + ':' + value.slice(2, 4)
      } else if (value.search(/^\d{1}:\d{2}$/) == 0) {
        // Autocorrect if h:mm
        return '0' + value
      } else if (value.search(/^\d{3}$/) == 0) {
        // Autocorrect if 3 digits
        return '0' + value.slice(0, 1) + ':' + value.slice(1, 3)
      } else if (value.length == 2) {
        return value + ':00'
      } else if (value.length == 1) {
        return '0' + value + ':00'
      } else {
        return false
      }
    },
    updateDate(newValue) {
      this.dt.date = newValue
      this.emitDate()
    },
    updateTime(newValue) {
      this.dt.time = newValue
    },
    emitTime() {
      this.timeErrors = []
      let timeInput = this.dt.time

      // Determine whether time is marked as utc or not with a 'z'
      let z = timeInput.includes('z')
      // If we have specificed it should be zulu then remove the z in order to validate
      z ? (timeInput = timeInput.replace('z', '')) : false

      let validated_time = this.validateTime(timeInput)
      if (validated_time || validated_time === '') {
        // Update input display with correctly formatted time
        if (validated_time && (this.utc || z)) {
          this.dt.time = validated_time + 'z'
        } else {
          this.dt.time = validated_time
        }
        let output = this.makeOutput()
        this.$emit('input', output)
      } else {
        this.timeErrors.push('hh:mm')
      }
    },
    emitDate() {
      let output = this.makeOutput()
      this.$emit('input', output)
    },
    makeOutput() {
      // Function to form the output string to be emitted from the component
      // Depends on whether we have a full date and time or just one of them
      let dtStr = this.dt.date + 'T' + this.dt.time

      if (this.dt.date && this.dt.time) {
        if (this.utc || this.dt.time.includes('z')) {
          let utcDt = DateTime.fromISO(dtStr.toUpperCase(), { zone: 'utc' })
          return utcDt.toISO()
        } else {
          let tzAware = DateTime.fromISO(dtStr, { zone: 'Europe/London' })
          return tzAware.toISO()
        }
      } else {
        return null
      }
    },
  },

  computed: {
    listeners() {
      return {
        // This will pass on the events to the parent component so they can listen
        ...this.$listeners,
        // We want to customise the input event however so will call our own function here
        input: this.supressDefaultInput,
      }
    },
    errorMessages() {
      if (this.errors) {
        let vuelidateErrors = this.errors.map((error) => error.$message)
        return this.timeErrors.concat(vuelidateErrors)
      } else {
        return this.timeErrors
      }
    },
  },
}
</script>
