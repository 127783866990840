import { apiMutations, apiActions, apiGetters } from '@/helpers/api.js'

export const namespaced = true

export const state = {
  endpoint: '/course_subscriptions',
  list: {
    fields: '{id, course{name}, student{full_name}, start_date, end_date}',
    default: null,
    filtered: null,
    fetching: false,
    paginationOptions: {
      itemsPerPage: 5,
    },
    searchParams: {
      search: {
        label: 'Search',
        value: null,
      },
      student__first_name__icontains: {
        label: 'First Name',
        value: null,
      },
      student__last_name__icontains: {
        label: 'Last Name',
        value: null,
      },
    },
    currentFilters: {},
  },
  instance: {
    data: null,
  },
}

export const mutations = {
  ...apiMutations,
}

export const actions = {
  ...apiActions,
}

export const getters = {
  ...apiGetters,
}
