<template>
  <v-row>
    <v-col>
      <div class="font-weight-medium title" :class="{ 'mb-1': value }">
        {{ title }}
      </div>
      <div class="subtitle text--disabled">
        <slot name="body">
          <span class="value-text">{{ value }}</span>
        </slot>
      </div>
    </v-col>
    <v-col cols="12" sm="auto">
      <v-btn
        :color="btnColor"
        :outlined="outlined"
        :dark="dark"
        :disabled="disabled"
        :class="{ 'white--text': whiteText }"
        @click="$emit('submit')"
        >{{ btnText }}</v-btn
      >
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    title: {
      required: true,
      type: String,
    },
    value: {
      required: false,
      type: String,
    },
    btnText: {
      required: true,
      type: String,
    },
    btnColor: {
      type: String,
      default: 'primary',
    },
    dark: {
      type: Boolean,
      default: false,
    },
    whiteText: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    disabled: {
      required: true,
    },
  },
}
</script>

<style scoped>
.item {
  min-height: 0px;
}
.title {
  font-size: 1.1rem !important;
}
.subtitle {
  font-size: 1rem !important;
}
.value-text {
  white-space: break-spaces;
}
</style>
