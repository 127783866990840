import { render, staticRenderFns } from "./BaseCrudTable.vue?vue&type=template&id=6f517f17&scoped=true&"
import script from "./BaseCrudTable.vue?vue&type=script&lang=js&"
export * from "./BaseCrudTable.vue?vue&type=script&lang=js&"
import style0 from "./BaseCrudTable.vue?vue&type=style&index=0&id=6f517f17&prod&lang=scss&"
import style1 from "./BaseCrudTable.vue?vue&type=style&index=1&id=6f517f17&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f517f17",
  null
  
)

export default component.exports