<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          small
          icon
          v-bind="attrs"
          v-on="on"
          tabindex="-1"
          id="userMenuBtn"
        >
          <v-icon>mdi-account-circle</v-icon>
        </v-btn>
      </template>

      <v-list dense nav flat>
        <v-list-item-group active-class="no-highlight" max="0">
          <!-- Mode Swap -->
          <!-- <v-list-item v-if="isStaff">
            <v-list-item-icon class="mr-4">
              <v-icon>mdi-account-convert</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-if="staffMode" @click="toggleStaffMode"
                >Personal Account</v-list-item-title
              >
              <v-list-item-title v-else @click="toggleStaffMode"
                >Staff Account</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item> -->

          <!-- Normal Menu -->
          <v-list-item
            link
            @click="$router.push({ name: item.route })"
            v-for="(item, index) in items"
            :key="index"
            :disabled="disabled"
            active-class="no-highlight"
          >
            <v-list-item-icon class="mr-4">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import api from '@/services/axios.js'

export default {
  name: 'UserMenu',
  data() {
    return {
      items: [
        { icon: 'mdi-account-box-outline', title: 'Profile', route: 'Profile' },
        { icon: 'mdi-logout', title: 'Logout', route: 'Logout' },
      ],
      disabled: false,
    }
  },
  methods: {
    changeTenant(tenant_id) {
      this.disabled = true
      this.$NProgress.start()
      api
        .post('/change-tenant/', { requested_tenant: tenant_id })
        .then((response) => {
          window.location = response.data.url
        })
    },
    ...mapActions('user', ['toggleStaffMode']),
  },
  computed: {
    ...mapGetters('user', ['availableTenants', 'staffMode', 'isStaff']),
  },
}
</script>

<style>
/* .no-highlight:before {
  background-color: transparent !important;
} */
.no-highlight .v-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
</style>
