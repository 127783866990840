import { apiMutations, apiActions, apiGetters } from '@/helpers/api.js'

export const namespaced = true

export const state = {
  endpoint: '/aircraft',
  list: {
    fields:
      '{id, reg, type, base, latest_hours, defects{id, description}, active, annual_due, insurance_expiry, radio_expiry, arc_expiry}',
    default: null,
    filtered: null,
    fetching: false,
    paginationOptions: {
      itemsPerPage: 100,
    },
    searchParams: {
      search: {
        label: 'Search',
        value: null,
      },
    },
    currentFilters: {},
  },
  instance: {
    fields: '',
    data: null,
  },
}

export const mutations = {
  ...apiMutations,
}

export const actions = {
  ...apiActions,
}

export const getters = {
  ...apiGetters,
}
