import api from '@/services/axios.js'

export function getParams(searchParams, pagination, resetPagination, fields) {
  // Loop through the searchParams and create an object that contains only
  // those fields with active values. We will set this in the store so the
  // filters can be displayed as widgets
  let currentFilters = {}
  for (let param in searchParams) {
    if (searchParams[param].value) {
      //currentFilters[param] = Object.assign({}, searchParams[param])
      currentFilters[param] = { ...searchParams[param] }
    }
  }

  // We don't want to combine quick search with any other filters
  let removeSearch
  if ('search' in currentFilters && Object.keys(currentFilters).length > 1) {
    delete currentFilters['search']
    removeSearch = true
  } else {
    removeSearch = false
  }

  // The payload for the api just needs the values to reduce to just key:value
  let parsedParams = {}
  for (let param in currentFilters) {
    if (typeof currentFilters[param].value === 'object') {
      // Use just ID for the api payload
      parsedParams[param] = currentFilters[param].value.id
      // Set output text in currentFilters based on the display field
      let displayField = currentFilters[param].display
      currentFilters[param].output = currentFilters[param].value[displayField]
    } else {
      parsedParams[param] = currentFilters[param].value
      // Output the same as the value
      currentFilters[param].output = currentFilters[param].value
    }
  }

  let params = {
    limit: pagination.itemsPerPage,
    ...parsedParams,
  }

  if (resetPagination) {
    pagination.page = 1
  }

  // Only want to include offset if it's not page 1
  if (pagination.page && pagination.page != 1) {
    params['offset'] = (pagination.page - 1) * pagination.itemsPerPage
  }

  // Also want to return a flag that tells us whether the query is filtered or not
  // Will be filtered if anything other than limit is present in params
  let isFiltered = Object.keys(params).length > 1 ? true : false

  // Finally add in the fields that we want to get back
  if (fields) {
    params['query'] = fields
  }

  return { params, currentFilters, isFiltered, removeSearch }
}

export const apiMutations = {
  SET_LIST_DEFAULT(state, { data }) {
    state.list.default = data
  },
  SET_LIST_FILTERED(state, { data, currentFilters }) {
    state.list.filtered = data
    state.list.currentFilters = currentFilters
  },
  UPDATE_SEARCH_PARAM(state, { target, value }) {
    state.list.searchParams[target].value = value
  },
  SET_INSTANCE(state, { data }) {
    state.instance.data = data
  },
  SET_FETCHING(state, value) {
    state.list.fetching = value
  },
  RESET_FILTERED(state) {
    state.list.filtered = null

    // Clear the search inputs
    for (let param in state.list.searchParams) {
      state.list.searchParams[param].value = null
    }

    // Clear the currently applied filters
    state.list.currentFilters = {}
  },
  UPDATE_PAGINATION(state, { options }) {
    state.list.paginationOptions = options
  },
  RESET_PAGINATION(state) {
    state.list.paginationOptions.page = 1
  },
  CLEAR_QUICK_SEARCH(state) {
    state.list.searchParams.search.value = null
  },
  STORE_QUERY_STRING(state, parsedParams) {
    let str = '?' + new URLSearchParams(parsedParams).toString()
    state.list.queryString = str
  },
}

export const apiActions = {
  fetchList({ state, commit }, { resetPagination = false } = {}) {
    let { params, currentFilters, isFiltered, removeSearch } = getParams(
      state.list.searchParams,
      state.list.paginationOptions,
      resetPagination,
      state.list.fields
    )

    // Store the parameters used for the API request so they can be used for
    // requesting a report etc if needed later
    commit('STORE_QUERY_STRING', params)

    commit('SET_FETCHING', true)

    return api.get(`${state.endpoint}.json`, { params }).then((response) => {
      if (!isFiltered) {
        commit('SET_LIST_DEFAULT', { data: response.data })
        commit('RESET_FILTERED')
      } else {
        commit('SET_LIST_FILTERED', {
          data: response.data,
          currentFilters,
        })
      }

      // If we want to reset the pagination we can do that now
      if (resetPagination) {
        commit('RESET_PAGINATION')
      }

      // Need to remove quick search if clashes with more specific filters
      if (removeSearch) {
        commit('CLEAR_QUICK_SEARCH')
      }

      commit('SET_FETCHING', false)
    })
  },

  loadList({ state, dispatch }, { params }) {
    // Use when entering a route to check whether the data has been loaded yet

    // If we don't have any data yet then fetch first and return once data retrieved
    if (params.forceRefresh || !state.list.default) {
      return dispatch('fetchList', {
        pagination: {
          itemsPerPage: state.list.paginationOptions.itemsPerPage,
          page: 1,
        },
        isDefault: true,
      })
    }

    if (params.cacheOnly && state.list.default) {
      // If we do have data and only want to use the cache then can just return
      return
    }

    // If we make it this far then we have data but want to get the latest version
    // Start the call to get latest data
    dispatch('fetchList')
    // return to ensure route gets loaded
    return
  },

  loadInstance({ dispatch, commit }, { params }) {
    // Use when loading a route takes in the route params

    // In the event we already have the instance data (eg just created resource
    // and therefore have the data in the response, we can simply set this as the
    // instance and prevent a second API call

    // Otherwise we pass an endpoint and get the required data

    if (params.instance && !params.forceRefresh) {
      return commit('SET_INSTANCE', { data: params.instance })
    } else {
      return dispatch('retrieve', params.id)
    }
  },

  setSearchParam({ commit }, { target, value }) {
    //let value = event.target.value
    commit('UPDATE_SEARCH_PARAM', { target, value })
  },

  clearFilters({ commit, dispatch }) {
    // Clear the filtered data and search params
    commit('RESET_FILTERED')
    // Refetch latest default data
    return dispatch('fetchList', { resetPagination: true })
  },

  retrieve({ state, commit }, id) {
    let params = {}
    if (state.instance.fields) {
      params['query'] = state.instance.fields
    }
    return api
      .get(`${state.endpoint}/${id}.json`, { params })
      .then((response) => {
        commit('SET_INSTANCE', { data: response.data })
        return response.data
      })
  },

  updateInstance({ commit }, data) {
    commit('SET_INSTANCE', { data: data.data })
  },

  updatePagination({ commit, dispatch }, { options, supressFetch }) {
    commit('UPDATE_PAGINATION', { options })

    // Only send for for a new request if it's not the first first which
    // is triggered by the table component loading
    if (!supressFetch) {
      dispatch('fetchList')
    }
  },

  reloadInstance({ state, dispatch }) {
    return dispatch('retrieve', state.instance.data.id)
  },
}

export const apiGetters = {
  list(state) {
    if (state.list.filtered) {
      return state.list.filtered
    } else {
      return state.list.default
    }
  },
  searchParams(state) {
    return state.list.searchParams
  },
  currentFilters(state) {
    return state.list.currentFilters
  },
  instance(state) {
    return state.instance.data
  },
  endpoint(state) {
    return state.endpoint
  },
  createEndpoint(state) {
    return `${state.endpoint}.json`
  },
  updateEndpoint(state) {
    if (!state.instance.data) {
      return null
    } else {
      return `${state.endpoint}/${state.instance.data.id}.json`
    }
  },
  deleteEndpoint(state) {
    if (!state.instance.data) {
      return null
    } else {
      return `${state.endpoint}/${state.instance.data.id}.json`
    }
  },
  itemsPerPage(state) {
    return state.list.itemsPerPage
  },
  options(state) {
    return state.list.paginationOptions
  },
  editable(state) {
    return state.editable
  },
  fetching(state) {
    return state.list.fetching
  },
  queryString(state) {
    if (state.list.queryString) {
      return state.list.queryString
    } else {
      return ''
    }
  },
}
