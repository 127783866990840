<template>
  <v-container class="px-0">
    <v-row>
      <v-col class="d-flex justify-end">
        <v-btn color="primary" @click="$emit('save')" :disabled="disabled"
          >Save</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'BasePageButtons',

  data() {
    return {}
  },

  props: {
    disabled: {
      required: true,
      type: Boolean,
    },
  },
}
</script>
