import store from '@/store'

const invoicingRoutes = [
  {
    path: '/invoicing',
    name: 'Invoicing',
    meta: { permissions: [], linkTag: 'invoicing' },
    component: () => import('@/views/invoicing/Invoicing.vue'),
    beforeEnter(routeTo, routeFrom, next) {
      store
        .dispatch(`flights/getUninvoiced`, { params: routeTo.params })
        .then(() => next())
    },
  },
  {
    path: '/invoicing/new-invoice/',
    name: 'NewInvoice',
    meta: { permissions: [], linkTag: 'invoicing' },
    component: () => import('@/views/invoicing/NewInvoice.vue'),
  },
  {
    path: '/invoicing/:id/',
    name: 'InvoiceDetail',
    meta: { permissions: [], linkTag: 'invoicing' },
    component: () => import('@/views/invoicing/InvoiceDetail.vue'),
    beforeEnter(routeTo, routeFrom, next) {
      store
        .dispatch('invoices/loadInstance', { params: routeTo.params })
        .then(() => next())
    },
  },
]

export default invoicingRoutes
