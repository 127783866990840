<template>
  <div id="captcha">
    <script
      type="application/javascript"
      defer
      src="https://www.google.com/recaptcha/api.js?onload=onloadCallback"
    ></script>
    <component :is="'script'">
      var vue = document.getElementById('captcha').__vue__; function
      onComplete(token) { vue.captchaCompleted(token) }; function expired() {
      vue.captchaExpired() } ; function onloadCallback() { vue.resize() };
    </component>
    <div
      class="g-recaptcha captcha"
      :class="{
        'captcha-error': errors && errors.length,
      }"
      :style="captchaScale"
      data-sitekey="6LfY7zsqAAAAABodHsa8ZIdDqNNTJ5UqBdxuvT5f"
      data-callback="onComplete"
      data-expired-callback="expired"
      required
    ></div>
  </div>
</template>

<script>
export default {
  name: 'BaseCaptcha',

  props: {
    errors: {
      type: Array,
      required: false,
    },
  },

  data() {
    return {
      scale: 1,
    }
  },

  methods: {
    captchaCompleted(token) {
      this.$emit('input', token)
    },
    captchaExpired() {
      this.$emit('input', null)
    },
    resize() {
      // Get captcha container div
      let container = document.querySelector('#captcha')
      // Get captcha container width
      let width = container.offsetWidth
      // Work out scale
      let scale = width / 304
      // If scale is greater than 1 then set it to 1
      if (scale > 1) {
        this.scale = 1
      } else {
        this.scale = scale
      }
    },
  },

  computed: {
    captchaScale() {
      return {
        transform: `scale(${this.scale})`,
        transformOrigin: '0 0',
      }
    },
  },
}
</script>

<style scoped>
.captcha-error {
  border: 3px solid red;
  box-sizing: content-box;
}
.captcha {
  width: fit-content;
}
</style>
