import { apiMutations, apiActions, apiGetters } from '@/helpers/api.js'
import api from '@/services/axios.js'

export const namespaced = true

export const state = {
  endpoint: '/flights',
  list: {
    fields:
      '{id, captain{id, short_name}, customer{id, short_name}, instructor{id, short_name}, \
      flight_type{short_name}, aircraft{reg}, off_blocks, on_blocks, total_block, is_international, \
      departure{ident}, arrival{ident}, takeoff_landings, solo, \
      invoice, customer_short_name}',
    default: null,
    filtered: null,
    queryString: '',
    fetching: false,
    paginationOptions: {
      itemsPerPage: 10,
    },
    searchParams: {
      search: {
        label: 'Search',
        value: null,
      },
      date: {
        label: 'Date',
        value: null,
      },
      date__gte: {
        label: 'Date From',
        value: null,
      },
      date__lte: {
        label: 'Date To',
        value: null,
      },
      aircraft: {
        label: 'Aircraft',
        value: null,
        display: 'reg',
      },
      pilot: {
        label: 'Pilot',
        value: null,
        display: 'short_name',
      },
      temp_customer__icontains: {
        label: 'Temporary Customer',
        value: null,
      },
      airfield: {
        label: 'Airfield',
        value: null,
        display: 'ident',
      },
      flight_type: {
        label: 'Flight Type',
        value: null,
        display: 'name',
      },
      base: {
        label: 'Base',
        value: null,
        display: 'name',
      },
    },
    currentFilters: {},
  },
  instance: {
    fields: '',
    data: null,
  },
  uninvoiced: null,
  open: null,
}

export const mutations = {
  ...apiMutations,
  SET_UNINVOICED(state, data) {
    state.uninvoiced = data
  },
  SET_OPEN_FLIGHTS(state, data) {
    state.open = data
  },
}

export const actions = {
  ...apiActions,
  getUninvoiced({ commit }) {
    return api
      .get(
        '/flights/?invoice__isnull=true&query={id,base,date,customer, customer_name, aircraft{reg},flight_type}&limit=100'
      )
      .then((res) => {
        commit('SET_UNINVOICED', res.data)
        return
      })
  },
  fetchOpen({ commit }) {
    let query =
      '{id, aircraft{reg}, captain{id, short_name}, customer{id, short_name}, flight_type{name}, base}'
    return api.get(`/flights/open/?query=${query}&limit=100`).then((res) => {
      commit('SET_OPEN_FLIGHTS', res.data)
    })
  },
  loadOpenFlights({ state, dispatch }) {
    // If open flights is null (ie we have already fetched) then
    // go fetch and return the promise
    if (state.open == null) {
      return dispatch('fetchOpen')
    } else {
      // Go fetch again but return straight away to allow route to load
      // with whatever we last had
      dispatch('fetchOpen')
      return
    }
  },
}

export const getters = {
  ...apiGetters,
  uninvoiced(state) {
    return state.uninvoiced ? state.uninvoiced.results : []
  },
  baseUninvoiced(state, getters, rootState, rootGetters) {
    if (state.uninvoiced) {
      let base = rootGetters['user/base']
      return state.uninvoiced.results.filter((flight) => flight.base == base.id)
    } else {
      return []
    }
  },
  open(state) {
    return state.open ? state.open : []
  },
  baseOpen(state, getters, rootState, rootGetters) {
    if (state.open) {
      let base = rootGetters['user/base']
      return state.open.filter((flight) => flight.base == base.id)
    } else {
      return []
    }
  },
}
