import { apiMutations, apiActions, apiGetters } from '@/helpers/api.js'

export const namespaced = true

export const state = {
  endpoint: '/techlogs',
  list: {
    fields: '{id}',
    default: null,
    filtered: null,
    paginationOptions: {
      itemsPerPage: 100,
    },
    searchParams: {
      search: {
        label: 'Search',
        value: null,
      },
    },
    currentFilters: {},
  },
  instance: {
    fields: '',
    data: null,
  },
}

export const mutations = {
  ...apiMutations,
}

export const actions = {
  ...apiActions,
}

export const getters = {
  ...apiGetters,
}
