import axios from 'axios'
import store from '@/store'
import NProgress from 'nprogress'
import router from '@/router'
import VueCookies from 'vue-cookies'
import { captureMessage } from '@sentry/vue'

const api = axios.create({
  // The single Axios instance we use for calls
  baseURL: `/api`,
  withCredentials: true, // This is the default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 25000,
})

api.interceptors.request.use((config) => {
  // Called on request
  config.headers['X-CSRFToken'] = VueCookies.get('csrftoken')
  return config
})

// Called on response
api.interceptors.response.use(
  (response) => {
    // Check SPA version matches the latest release
    store.dispatch('app/checkAppVersion', response.headers['x-app-version'])
    if (response.config.url != '/config/' && store.getters['user/loggedIn']) {
      store.dispatch(
        'app/checkConfigVersion',
        response.headers['x-config-version']
      )
    }
    return response
  },
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 400:
          // Break on a 400 as we want to handle rest within the component
          break
        case 404:
          router.push({ name: '404' })
          break
        case 500:
          router.push({ name: '500' })
          break
        case 403:
          if (process.env.NODE_ENV == 'development') {
            // On development display warning
            store.dispatch('notification/add', {
              type: 'warning',
              message: `Code ${error.response.status} - ${error.response.statusText}`,
            })
          } else {
            // On production logout user
            store.dispatch('user/unauthorisedLogout')
          }
          break
        case 480:
          store.dispatch('notification/add', {
            type: 'warning',
            message: `${error.response.data}`,
          })
          break
        case 429:
          // 429 return from rest framework throttling
          store.dispatch('notification/add', {
            type: 'warning',
            message: `${error.response.data.detail}`,
          })
          break
        default:
          NProgress.done()
          store.dispatch('notification/add', {
            type: 'warning',
            message: `Code ${error.response.status} - ${error.response.statusText}`,
          })
      }
    } else {
      // For debugging send message to sentry
      if (navigator.onLine) {
        // Get connection information
        let connectionInfo = 'unknown'
        if (navigator.connection) {
          connectionInfo = {
            effectiveType: navigator.connection.effectiveType,
            downlink: navigator.connection.downlink,
            rtt: navigator.connection.rtt,
          }
        }

        // Send to sentry if user has a connection
        captureMessage('Axios error with no response object', {
          level: 'warning',
          extra: {
            error: error,
            connectionInfo: connectionInfo,
          },
          replay: false,
        })
      }
    }
    return Promise.reject(error)
  }
)

export default api
