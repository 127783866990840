import Vue from 'vue'
import Vuex from 'vuex'
import * as user from '@/store/modules/user.js'
import * as app from '@/store/modules/app.js'
import * as members from '@/store/modules/members.js'
import * as notification from '@/store/modules/notification.js'
import * as flightTypes from '@/store/modules/flightTypes.js'
import * as records from '@/store/modules/records.js'
import * as courseSubscriptions from '@/store/modules/courseSubscriptions.js'
import * as courses from '@/store/modules/courses.js'
import * as aircraft from '@/store/modules/aircraft.js'
import * as crs from '@/store/modules/crs.js'
import * as techlog from '@/store/modules/techlog.js'
import * as airports from '@/store/modules/airports.js'
import * as flights from '@/store/modules/flights.js'
import * as defects from '@/store/modules/defects.js'
import * as downloads from '@/store/modules/downloads.js'
import * as invoices from '@/store/modules/invoices.js'
import * as products from '@/store/modules/products.js'
import * as vouchers from '@/store/modules/vouchers.js'
import * as notices from '@/store/modules/notices.js'
import * as nav from '@/store/modules/nav.js'
import * as instructors from '@/store/modules/instructors.js'
import * as voucherPortal from '@/store/modules/voucherPortal.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    notification,
    user,
    members,
    flightTypes,
    records,
    courseSubscriptions,
    courses,
    aircraft,
    crs,
    techlog,
    airports,
    flights,
    defects,
    downloads,
    invoices,
    products,
    vouchers,
    notices,
    nav,
    instructors,
    voucherPortal,
  },
  state: {},
  mutations: {},
  actions: {},
})
