<template>
  <v-navigation-drawer
    :value="active"
    @input="input"
    app
    temporary
    right
    width="420px"
    class="pb-6 nav-drawer"
    touchless
  >
    <v-list-item id="base-nav-drawer">
      <v-list-item-avatar v-if="icon">
        <v-icon>{{ icon }}</v-icon>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title> {{ title }} </v-list-item-title>
      </v-list-item-content>
      <v-btn v-if="$vuetify.breakpoint.mobile" icon @click="$emit('close')"
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </v-list-item>

    <v-divider></v-divider>

    <v-container>
      <slot></slot>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'BaseDrawer',
  data() {
    return {}
  },
  props: {
    active: {
      required: true,
      type: Boolean,
    },
    icon: {
      required: false,
    },
    title: {
      required: true,
    },
  },

  methods: {
    input(active) {
      if (!active) {
        this.$emit('close')
      }
    },
  },
}
</script>

<style>
.nav-drawer {
  /* Dynamic for mobile ios */
  height: 100dvh !important;
}
</style>
