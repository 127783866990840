import { render, staticRenderFns } from "./BaseCaptcha.vue?vue&type=template&id=5f83f023&scoped=true&"
import script from "./BaseCaptcha.vue?vue&type=script&lang=js&"
export * from "./BaseCaptcha.vue?vue&type=script&lang=js&"
import style0 from "./BaseCaptcha.vue?vue&type=style&index=0&id=5f83f023&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f83f023",
  null
  
)

export default component.exports