<template>
  <v-container class="height-100 login-container">
    <v-card class="login-card py-6">
      <v-card-title class="flex-column pb-0">
        <v-icon size="70px">{{ icon }}</v-icon>
        <p class="mt-4">{{ title }}</p>
        <p></p
      ></v-card-title>
      <v-container>
        <v-row class="px-7">
          <v-col>
            <slot></slot>
          </v-col>
        </v-row>
        <v-row class="px-7">
          <v-col class="d-flex justify-center">
            <v-btn color="primary" @click="reload"
              ><v-icon small class="mr-1">mdi-home</v-icon> Home</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'TokenExpired',

  data() {
    return {}
  },

  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },

  methods: {
    reload() {
      // Reload back to the index of the site which will clear all js data too
      const href = location.origin
      location.href = href
    },
  },
}
</script>

<style scoped>
.height-100 {
  height: 100%;
}
.login-container {
  max-width: 500px !important;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}
.login-card {
  margin-top: 10vh;
  flex-grow: 1;
}
.logo {
  max-height: 100px;
}
</style>
