<template>
  <v-autocomplete
    ref="user-select"
    v-bind="attrs"
    v-on="listeners"
    :error-messages="errorMessages"
    :hide-details="hideDetails"
    :loading="loading"
    :items="items"
    :search-input.sync="search"
    item-value="id"
    item-text="full_name"
    @keydown="tabNext"
    @click="onFocus"
    :no-data-text="emptyText"
    :outlined="outlined"
    auto-select-first
    class="base-user-select"
    data-np-disabled="true"
    :menu-props="{ maxHeight: 'min(30vh, 300px)' }"
  >
    <template v-slot:label>
      <slot name="label" v-if="$attrs.label">{{ $attrs.label }}</slot>
    </template>
    <template v-slot:item="{ item }">
      <div class="d-flex align-center justify-space-between w-100">
        <div class="user-select-item">
          <div>
            {{ item.full_name }}
          </div>
          <div class="email">
            {{ item.email }}
          </div>
        </div>
        <div class="align-right ml-3">
          <v-icon v-if="!item.active">mdi-account-off-outline</v-icon>
        </div>
      </div>
    </template>
    <template v-slot:append-item v-if="addCustomer && !search && !items.length">
      <div
        class="d-flex align-center new-customer-opt py-3 px-4"
        @click="addNewCustomer"
      >
        <v-icon>mdi-account-plus</v-icon>
        <span class="ml-3">{{ addCustomer }}</span>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
import { tabNext, syncMixin } from '@/helpers/components.js'
import api from '@/services/axios'
export default {
  name: 'BaseUserSelect',
  mixins: [syncMixin],

  props: {
    errors: {
      required: false,
      default() {
        return []
      },
    },
    hideDetails: {
      required: false,
      default: 'auto',
    },
    outlined: {
      required: false,
      default: true,
    },
    addCustomer: {
      required: false,
      default: false,
    },
    scrollOnFocus: {
      required: false,
      default: false,
    },
  },

  data() {
    return {
      items: [],
      loading: false,
      search: '',
      limit: 15,
      lastRequest: {
        value: '',
        itemsFound: 0,
      },
      fields:
        '{id, first_name, last_name, short_name, full_name, email, active}',
      tabNext, // Correctly sequences tab behaviour
      debouncer: null,
    }
  },

  created() {
    // Add the current user to the list
    this.syncItems(this.items)
  },

  methods: {
    test() {
      console.log('chabged')
    },
    searchItemsDebouncer() {
      // Debounce the search
      clearTimeout(this.debouncer)
      // If no items then search immediately
      if (!this.loading && !this.items.length) {
        this.searchItems()
      } else {
        this.debouncer = setTimeout(this.searchItems, 200)
      }
    },
    searchItems() {
      // Only search when the user has typed at least 3 characters
      if (!this.search || this.search.length < 3) {
        // Reset the items and last request
        // This helps if the user deletes their search term and starts again
        this.items = []
        this.lastRequest.value = ''
        this.lastRequest.itemsFound = 0
        // If there is currently a selected value then clear it
        if (!this.search && this.$attrs.value) {
          this.$emit('input', null)
        }
        return
      }

      // Check to see if the search term has the same beginning as the last request
      if (
        this.lastRequest.value &&
        this.search.startsWith(this.lastRequest.value)
      ) {
        // If the last request found no items, don't bother searching again
        if (this.lastRequest.itemsFound === 0) return

        // If the last request found less than the limit, don't bother searching again
        if (this.lastRequest.itemsFound < this.limit) return
      }

      // Display loading bar
      this.loading = true

      // Execute search
      api
        .get('/user_search/', {
          params: {
            search: this.search,
            limit: this.limit,
            query: this.fields,
          },
        })
        .then((res) => {
          this.items = res.data.results
          this.loading = false
          // Save details of this request
          this.lastRequest.value = this.search
          this.lastRequest.itemsFound = res.data.results.length

          // Bug where if the list ends up reducing to zero (due search term being used)
          // then the autoselect first item doesn't work anyway. Therefore manually
          // highlight the first item after each api call
          const dropdown =
            this.$refs['user-select'].$refs['menu'].$refs['content']
          const firstItem = dropdown.querySelector('.v-list-item')
          setTimeout(() => {
            firstItem.classList.add('v-list-item--highlighted')
          }, 5)
        })
    },
    fullName(item) {
      return item.first_name + ' ' + item.last_name
    },
    addNewCustomer() {
      // Remove focus from the menu
      this.$refs['user-select'].$refs['menu'].isActive = false
      // Emit the event
      this.$emit('add-customer')
    },
    onFocus() {
      if (this.scrollOnFocus) {
        this.$nextTick(() => {
          // Scroll this element to the top of the page
          this.$el.scrollIntoView({ behavior: 'smooth', block: 'start' })
        })
      }
    },
  },

  computed: {
    listeners() {
      return {
        ...this.$listeners,
      }
    },
    errorMessages() {
      if (this.errors) {
        return this.errors.map((error) => error.$message)
      } else {
        return []
      }
    },
    attrs() {
      return {
        ...this.$attrs,
        returnObject: this.$attrs.returnObject
          ? this.$attrs.returnObject
          : true,
      }
    },
    emptyText() {
      if (!this.search || this.loading || this.search.length < 3) {
        return 'Enter name to search'
      } else {
        return 'No results found'
      }
    },
    value() {
      return this.$attrs.value
    },
  },

  watch: {
    search() {
      // Dont search if matches the actual value selected
      if (this.$attrs.value) {
        let full_name = this.$attrs.value.full_name
        if (full_name === this.search) {
          return
        }
      }
      this.searchItemsDebouncer()
    },
    value() {
      // If we directly set the value we need to sync again otherwise
      // it wont find the value in the list
      this.syncItems(this.items)
    },
  },
}
</script>

<style lang="scss">
.user-select-item {
  .email {
    font-size: 0.8rem;
  }
}
</style>

<style lang="scss" scoped>
.new-customer-opt {
  cursor: pointer;
  font-size: 0.9rem;
  &:hover {
    background-color: #f5f5f5;
  }
}
</style>
