<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn text v-bind="attrs" v-on="on" tabindex="-1">
          <v-icon small>mdi-airport</v-icon>
          <span class="ml-2">{{ $base.name }}</span>
        </v-btn>
      </template>

      <v-list dense nav flat :key="menuKey">
        <v-list-item-group active-class="no-highlight" max="0">
          <v-list-item
            link
            v-for="(item, index) in bases"
            :key="index"
            :disabled="item.id == $base.id"
            active-class="no-highlight"
            @click="changeBase(item)"
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'BaseMenu',
  data() {
    return {
      disabled: false,
      menuKey: 1,
    }
  },
  methods: {
    changeBase(base) {
      this.$store.dispatch('user/changeBase', base)
      this.menuKey++
      // Reload to parent
      if (this.$route.meta.parent) {
        this.$router.push({ name: this.$route.meta.parent })
      }
      if (this.$store.getters['app/unsavedDialog']) {
        this.$store.dispatch('app/confirmNav')
      }
    },
  },
  computed: {
    ...mapGetters('app', ['bases']),
  },
}
</script>

<style>
/* .no-highlight:before {
  background-color: transparent !important;
} */
.no-highlight .v-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}
</style>
