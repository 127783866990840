import { apiMutations, apiActions, apiGetters } from '@/helpers/api.js'

export const namespaced = true

export const state = {
  endpoint: '/crs',
  list: {
    fields:
      '{id, last_check_date, last_check_hours, last_check_hours_decimal, last_check_type}',
    default: null,
    filtered: null,
    fetching: false,
    paginationOptions: {
      itemsPerPage: 5,
    },
    searchParams: {
      search: {
        label: 'Search',
        value: null,
      },
      aircraft: {
        label: 'Aircraft',
        value: null,
      },
    },
    currentFilters: {},
  },
  instance: {
    fields: '',
    data: null,
  },
}

export const mutations = {
  ...apiMutations,
}

export const actions = {
  ...apiActions,
}

export const getters = {
  ...apiGetters,
}
