import store from '@/store'
// import api from '@/services/axios.js'

const noticesRoutes = [
  {
    path: '/notices',
    name: 'NoticeList',
    meta: { permissions: [], linkTag: 'notices' },
    component: () => import('@/views/notices/NoticeList.vue'),
    // Retrieve notices for the user and pass them to the component
    beforeEnter: (to, from, next) => {
      // Ensure user notices are loaded
      store.dispatch('notices/fetchUserNotices').then(() => {
        next()
      })
    },
  },
  {
    path: '/notices/view/:id',
    name: 'NoticeView',
    meta: { permissions: [], linkTag: 'notices' },
    component: () => import('@/views/notices/NoticeView.vue'),
    // Retrieve notices for the user and pass them to the component
    beforeEnter: (to, from, next) => {
      // Ensure user notices are loaded
      store.dispatch('notices/viewNotice', to.params.id).then(() => {
        next()
      })
    },
  },
  {
    path: '/notice-send',
    name: 'NoticeSend',
    meta: { permissions: [], linkTag: 'notices' },
    component: () => import('@/views/notices/NoticeSend.vue'),
  },
]

export default noticesRoutes
