import { apiMutations, apiActions, apiGetters } from '@/helpers/api.js'
import api from '@/services/axios.js'

export const namespaced = true

export const state = {
  endpoint: '/vouchers',
  list: {
    fields:
      '{id, serial, purchaser_first_name, purchaser_last_name, supplier{name}, \
      purchased_type, status, draft, redeemer_first_name, redeemer_last_name}',
    default: null,
    filtered: null,
    queryString: '',
    fetching: false,
    paginationOptions: {
      itemsPerPage: 10,
    },
    searchParams: {
      search: {
        label: 'Search',
        value: null,
      },
      serial__icontains: {
        label: 'Serial',
        value: null,
      },
      issued: {
        label: 'Issue Date',
        value: null,
        display: 'Issue Date',
      },
      issued__gte: {
        label: 'Issued From',
        value: null,
        display: 'Issued From',
      },
      issued__lte: {
        label: 'Issued To',
        value: null,
        display: 'Issued To',
      },
      customer: {
        label: 'Customer',
        value: null,
      },
      expiry__gte: {
        label: 'Expires on or after',
        value: null,
      },
    },
    currentFilters: {},
  },
  instance: {
    fields: '',
    data: null,
  },
  drafts: [],
}

export const mutations = {
  SET_DRAFTS(state, drafts) {
    state.drafts = drafts
  },
  ...apiMutations,
}

export const actions = {
  fetchDrafts({ commit, state }) {
    api
      .get(`/vouchers/?draft=true&limit=100&query=${state.list.fields}`)
      .then((res) => {
        commit('SET_DRAFTS', res.data.results)
      })
  },
  ...apiActions,
}

export const getters = {
  drafts() {
    return state.drafts
  },
  ...apiGetters,
}
