<template>
  <v-alert
    :text="true"
    :outlined="noFill"
    :type="type"
    :icon="icon"
    border="left"
    class="px-5 mb-0"
    :class="{ 'alert-icon-center': centerIcon }"
  >
    <slot></slot>
  </v-alert>
</template>

<script>
export default {
  name: 'BaseAlert',
  data: function () {
    return {
      return() {},
    }
  },
  props: {
    type: {
      required: true,
    },
    noFill: {
      required: false,
      default: false,
    },
    icon: {
      required: false,
    },
    centerIcon: {
      required: false,
      default: false,
    },
  },
}
</script>

<style lang="scss">
.v-alert {
  // Change colours for info alert to help make text stand out
  &.info--text {
    .v-alert__wrapper {
      color: var(--v-primary-base) !important;
    }
    .v-alert__icon {
      color: var(--v-primary-base) !important;
    }
  }
}

.alert-icon-center {
  .v-alert__icon {
    align-self: center;
  }
}
</style>
