import api from '@/services/axios.js'
import router from '@/router'
import { getSubdomain } from '@/helpers/utility.js'

export const namespaced = true

export const state = {
  tenant_branding: null,
  app_version: null,
  editable: [],
  unsavedDialog: {
    display: false,
    next: null,
  },
  // When we cancel nav due to unsaved changed we need to re-render the nav bar
  // links otherwise the selected item stays on the previously requested one
  navBarKey: 1,
  config: null,
  fetchingConfig: false,
  dashboardData: null,
  connectionWarning: false,
  onlineStatus: true,
  routeLoading: false,
  routeLoadingTimeout: null,
}

export const mutations = {
  SET_BRANDING(state, payload) {
    state.tenant_branding = payload
    // Update Favicon
    let favicon = document.getElementById('favicon')
    favicon.href = payload.asset_href + 'favicon.png'
    // Update site title
    let title = document.getElementById('site-title')
    if (process.env.NODE_ENV == 'development') {
      title.innerText = 'DEVELOPMENT - ' + payload.name
    } else {
      title.innerText = payload.name
    }
  },
  ADD_TO_EDITABLE(state, value) {
    state.editable.push(value)
  },
  REMOVE_FROM_EDITABLE(state, value) {
    state.editable.splice(state.editable.indexOf(value), 1)
  },
  SET_UNSAVED_DIALOG_DISPLAY(state, value) {
    state.unsavedDialog.display = value
  },
  SET_UNSAVED_DIALOG_NEXT(state, value) {
    state.unsavedDialog.next = value
  },
  CLEAR_EDITABLE(state) {
    state.editable = []
  },
  RELOAD_NAV_BAR(state) {
    state.navBarKey++
  },
  SET_APP_VERSION(state, value) {
    state.app_version = value
  },
  SET_CONFIG(state, config) {
    state.config = config
  },
  SET_FETCHING_CONFIG(state, value) {
    state.fetchingConfig = value
  },
  SET_DASHBOARD_DATA(state, data) {
    state.dashboardData = data
  },
  SET_CONNECTION_WARNING(state, value) {
    state.connectionWarning = value
  },
  SET_CONNECTION_WATCHER(state, value) {
    state.watchOnlineStatus = value
  },
  SET_ROUTE_LOADING(state, value) {
    state.routeLoading = value
  },
  SET_ROUTE_LOADING_TIMEOUT(state, value) {
    clearTimeout(state.routeLoadingTimeout)
    state.routeLoadingTimeout = value
  },
}

export const actions = {
  fetchBranding({ state, commit }) {
    if (state.tenant_branding) {
      return
    } else if (getSubdomain()) {
      // Initially set something otherwise if the request 404s then the router
      // wil try and reload the 404 page which will trigger another fetch of the
      // branding
      commit('SET_BRANDING', 'fetching')
      return api.get('/tenant-branding/').then((response) => {
        return commit('SET_BRANDING', response.data)
      })
    }
  },

  fetchConfig({ commit }) {
    commit('SET_FETCHING_CONFIG', true)
    return api
      .get('/config/')
      .then((res) => {
        commit('SET_CONFIG', res.data)
        commit('SET_FETCHING_CONFIG', false)
        return
      })
      .catch(() => {
        commit('SET_CONFIG', null)
        commit('SET_FETCHING_CONFIG', false)
      })
  },

  // Form Editing
  edit({ commit }, value) {
    commit('ADD_TO_EDITABLE', value)
  },
  // Edit is clashing with edit prop on forms
  editMode({ commit }, value) {
    commit('ADD_TO_EDITABLE', value)
  },
  cancelEdit({ state, commit }, value) {
    if (state.editable.includes(value)) {
      commit('REMOVE_FROM_EDITABLE', value)
      return
    }
  },

  // Unsaved Dialog
  // This is called when we want to prompt user to confirm nav
  // Should pass in either a route object / tab index as 'next'
  unsavedDialog({ commit }, next) {
    commit('SET_UNSAVED_DIALOG_DISPLAY', true)
    commit('SET_UNSAVED_DIALOG_NEXT', next)
  },
  cancelNav({ commit }) {
    commit('SET_UNSAVED_DIALOG_DISPLAY', false)
    commit('SET_UNSAVED_DIALOG_NEXT', null)
    commit('RELOAD_NAV_BAR')
  },
  confirmNav({ commit, state }) {
    commit('SET_UNSAVED_DIALOG_DISPLAY', false)
    commit('CLEAR_EDITABLE')
    router.push(state.unsavedDialog.next)
    commit('SET_UNSAVED_DIALOG_NEXT', null)
  },
  checkAppVersion({ commit, state }, server_version) {
    // No app version will be defined the first time we get a response so can assume
    // we've got the latest version
    if (!state.app_version) {
      return commit('SET_APP_VERSION', server_version)
    } else if (state.app_version != server_version) {
      return location.reload() // Reload app to ensure latest files
    } else {
      return // If up to date can just retur
    }
  },
  checkConfigVersion({ dispatch, getters }, server_version) {
    if (this.fetchingConfig) {
      return // Exit if waiting on latest config to return
      // } else if (!getters.configVersion) {
      //   return dispatch('fetchConfig') // First fetch
    } else if (getters.configVersion != server_version) {
      return dispatch('fetchConfig') // Reload config
    } else {
      return // If up to date can just return
    }
  },
  loadConfig({ dispatch, getters }) {
    if (!getters.configVersion || !getters.configUser) {
      return dispatch('fetchConfig')
    } else {
      return true
    }
  },
  fetchDashboard({ commit, rootGetters }) {
    // Make api call to get dashboard data
    let params = {
      query: `{open_flights{id, aircraft{reg}, customer{full_name}, instructor{full_name}, flight_type, temp_customer, customer_name},\
      uninvoiced_today{id, aircraft{reg}, customer{full_name}, flight_type, temp_customer, customer_name} \
      vouchers{id, draft, dashboard_customer}}`,
      base: rootGetters['user/base'].id,
    }
    return api.get('/dashboard/', { params }).then((response) => {
      commit('SET_DASHBOARD_DATA', response.data)
    })
  },
  loadDashboard({ dispatch, state }) {
    if (state.dashboardData) {
      // If we have data already then fetch latest but don't wait for it
      dispatch('fetchDashboard')
      return
    } else {
      // If we don't have data then fetch and wait for it
      return dispatch('fetchDashboard')
    }
  },
  goToHome({ getters }, currentRoute) {
    // If we have specified a route in route meta use that
    if (currentRoute.meta.home) {
      if (currentRoute.meta.home == 'website') {
        window.location.href = `https://${getters.tenantBranding.website}`
        return
      } else {
        router.push({ name: currentRoute.meta.home })
      }
    } else {
      router.push({ name: 'Index' })
    }
  },
  setConnectionWarning({ commit }, value) {
    commit('SET_CONNECTION_WARNING', value)
  },
  setRouteLoading({ commit }, value) {
    if (value) {
      // Set a timeout to set loading to true after 500ms
      commit(
        'SET_ROUTE_LOADING_TIMEOUT',
        setTimeout(() => {
          commit('SET_ROUTE_LOADING', true)
        }, 500)
      )
    } else {
      // Clear the timeout if it exists
      commit('SET_ROUTE_LOADING_TIMEOUT', null)
      commit('SET_ROUTE_LOADING', false)
    }
  },
}

export const getters = {
  tenantBranding(state) {
    if (state.tenant_branding != 'fetching') {
      return state.tenant_branding
    } else {
      return null
    }
  },
  tenant(state) {
    return state.tenant_branding ? state.tenant_branding.shortcode : null
  },
  isEditable(state) {
    return (value) => {
      return state.editable.includes(value)
    }
  },
  editable(state) {
    return state.editable
  },
  unsavedDialog(state) {
    return state.unsavedDialog.display
  },
  navBarKey(state) {
    return state.navBarKey
  },
  configVersion(state) {
    if (state.config) {
      return state.config.version
    } else {
      return null
    }
  },
  fetchingConfig(state) {
    return state.fetchConfig
  },
  navBar() {
    return {
      breakpoint: 1280,
      width: 256,
    }
  },
  dashboardData(state) {
    return state.dashboardData
  },
  timeSlots() {
    // List of times from 07:00 to 21:00 in 30 minute intervals
    let times = []
    for (let i = 7; i < 21; i++) {
      times.push(String(i).padStart(2, '0') + ':00')
      times.push(String(i).padStart(2, '0') + ':30')
    }
    // Add final slot
    times.push('21:00')
    return times
  },
  ratingTypes(state) {
    if (state.config) {
      return state.config.rating_types
    } else {
      return []
    }
  },
  ratingChecks(state) {
    return state.config ? state.config.rating_checks : []
  },
  instructors(state) {
    return state.config ? state.config.instructors : []
  },
  aircraft(state) {
    return state.config ? state.config.aircraft : []
  },
  activeAircraft(state) {
    // Return only active aircraft
    return state.config
      ? state.config.aircraft.filter((aircraft) => aircraft.active)
      : []
  },
  baseAircraft(state, getters, rootState, rootGetters) {
    // Return only aircraft at the current base
    let base = rootGetters['user/base']
    return state.config
      ? state.config.aircraft.filter(
          (aircraft) => aircraft.base.id == base.id && aircraft.active
        )
      : []
  },
  aircraftTypes(state) {
    return state.config ? state.config.aircraft_types : []
  },
  checkTypes(state) {
    return state.config ? state.config.check_types : []
  },
  resolveCheckType(state) {
    return (value) => {
      return state.config.check_types.find((e) => e.value == value).name
    }
  },
  flightTypes(state) {
    return state.config ? state.config.flight_types : []
  },
  airports(state) {
    return state.config ? state.config.airports : []
  },
  bases(state) {
    return state.config ? state.config.bases : []
  },
  approachTypes(state) {
    return state.config ? state.config.approach_types : []
  },

  vatRates(state) {
    return state.config ? state.config.vat_rates : []
  },
  invoiceTypes(state, getters, rootState, rootGetters) {
    if (state.config) {
      let disabled = false
      let has_refund_perm = rootGetters['user/hasPerm']('invoice_admin')
      return state.config.invoice_types.map((type) => {
        if (type.protected && !has_refund_perm) {
          disabled = true
        }
        return {
          ...type,
          disabled,
        }
      })
    }
  },
  paymentMethods(state) {
    return state.config ? state.config.payment_methods : []
  },
  voucherSuppliers(state) {
    return state.config ? state.config.voucher_suppliers : []
  },
  voucherTypes(state) {
    return state.config ? state.config.voucher_types : []
  },
  tenantConfig(state) {
    return state.config ? state.config.tenant_config : []
  },
  membershipTypes(state) {
    return state.config ? state.config.membership_types : []
  },
  bookingTypes(state) {
    return state.config ? state.config.booking_types : []
  },
  rateTypes(state) {
    return state.config ? state.config.rate_types : []
  },
  configUser(state) {
    // Will help us determine whether this was public config or not
    return state.config ? state.config.requesting_user : false
  },
  emailTemplates(state) {
    return state.config ? state.config.email_templates : []
  },
  cancellationReasons(state) {
    return state.config ? state.config.cancellation_reasons : []
  },
  connectionWarning(state) {
    return state.connectionWarning
  },
  retailVouchers(state, getters) {
    // Filter voucherTypes to those which are available online
    return getters.voucherTypes.filter((type) => type.available_online)
  },
  routeLoading(state) {
    return state.routeLoading
  },
}
