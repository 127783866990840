import store from '@/store'

const bookingsRoutes = [
  {
    path: '/bookings',
    name: 'Bookings',
    meta: { permissions: [], linkTag: 'bookings' },
    component: () =>
      import(
        /* webpackChunkName: "bookings" */ '@/views/bookings/Bookings.vue'
      ),
    beforeEnter(routeTo, routeFrom, next) {
      store
        .dispatch(`aircraft/loadList`, { params: routeTo.params })
        .then(() => next())
    },
  },
  {
    path: '/roster',
    name: 'InstructorRoster',
    meta: { linkTag: 'roster' },
    component: () => import('@/views/instructors/InstructorRosterView.vue'),
    beforeEnter(routeTo, routeFrom, next) {
      let user = store.getters['user/user']
      store
        .dispatch(`instructors/loadInstance`, { params: { id: user.id } })
        .then(() => next())
    },
  },
  {
    path: '/roster/coverage',
    name: 'InstructorCoverage',
    meta: { permissions: ['instructorprofile_roster'], linkTag: 'roster' },
    component: () => import('@/views/admin/instructors/InstructorCoverage.vue'),
  },
]

export default bookingsRoutes
