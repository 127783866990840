import { apiMutations, apiActions, apiGetters } from '@/helpers/api.js'

export const namespaced = true

export const state = {
  endpoint: '/airports',
  list: {
    fields:
      '{id, name, ident, list_display, country_name, iso_country, municipality, \
      is_international}',
    default: null,
    filtered: null,
    paginationOptions: {
      itemsPerPage: 5,
    },
    searchParams: {
      search: {
        label: 'Search',
        value: null,
      },
    },
    currentFilters: {},
  },
  instance: {
    fields: '',
    data: null,
  },
}

export const mutations = {
  ...apiMutations,
}

export const actions = {
  ...apiActions,
}

export const getters = {
  ...apiGetters,
}
