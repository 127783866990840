<template>
  <v-menu
    ref="menu"
    v-model="menu"
    min-width="auto"
    class="date-input"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :error-messages="errorMessages"
        :hide-details="hideDetails"
        v-bind="attrs"
        v-model="date"
        outlined
        class="date-picker"
        type="text"
        :placeholder="placeholderText"
        required
        pattern="\d{2}-\d{2}-\d{4}"
        ref="text-input"
        @blur="validate"
        @keydown.enter="validate"
        :prepend-icon="prepend"
      >
        <template slot="append" v-if="!$attrs['disabled']">
          <v-icon
            @click="openPicker"
            v-on="on"
            tabindex="-1"
            ref="calIcon"
            class="cal-icon"
          >
            mdi-calendar
          </v-icon>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      :active-picker.sync="activePicker"
      :value="this.value"
      no-title
      @input="pickerSelect"
      format="en"
      :max="max"
      :picker-date.sync="pickerDate"
      @change="save"
    >
    </v-date-picker>
  </v-menu>
  <!-- <v-text-field
    :prepend-icon="prepend"
    :error-messages="errorMessages"
    :hide-details="hideDetails"
    v-bind="$attrs"
    v-model="date"
    outlined
    class="date-picker"
    type="text"
    :placeholder="placeholderText"
    required
    pattern="\d{2}-\d{2}-\d{4}"
    ref="text-input"
    @blur="validate"
    @keydown.enter="validate"
  >
    <v-menu
      v-if="!$attrs.disabled"
      v-model="dialog"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-x
      left
      min-width="auto"
      slot="append"
      ref="menu"
    >
      <template v-slot:activator="{ on }">
        <v-icon
          v-show="value && canClear"
          tabindex="-1"
          @click="clearDate"
          class="date-clear"
        >
          mdi-close
        </v-icon>
        <v-icon
          @click="openPicker"
          v-on="on"
          tabindex="-1"
          ref="calIcon"
          class="cal-icon"
        >
          mdi-calendar
        </v-icon>
      </template>
      <v-date-picker
        :active-picker.sync="activePicker"
        :value="this.value"
        no-title
        @input="pickerSelect"
        format="en"
        :max="max"
      >
      </v-date-picker>
    </v-menu>
  </v-text-field> -->
</template>

<script>
import { DateTime } from 'luxon'

export default {
  data() {
    return {
      menu: false,
      date: null, // Holds the str value of the date we can edit
      validationError: null,
      activePicker: null,
      max: null,
      touched: false,
      pickerDate: null,
    }
  },

  created() {
    this.setFromISO(this.value)
    // If this is a birthday, set max date to today
    if (this.birthday) {
      this.max = DateTime.local().toISODate()
    }

    // If we have an initial date, set it
    if (!this.value && this.initialDate) {
      this.pickerDate = this.initialDate
    }
  },

  inheritAttrs: false,
  props: {
    errors: {},
    value: [String, Number],
    autocomplete: {
      type: String,
      required: false,
      default: 'new-nope',
    },
    prepend: {
      type: String,
      required: false,
      default: undefined,
    },
    canClear: {
      default: true,
      type: Boolean,
    },
    hideDetails: {
      required: false,
      default: 'auto',
    },
    birthday: {
      required: false,
      default: false,
    },
    initialDate: {
      type: String,
      required: false,
      default: null,
    },
  },
  methods: {
    // onClick(e) {
    //   // If on mobile and the date picker is not open, open it
    //   // Only do it the first time the user focuses the input
    //   if (!this.touched && this.isMobile && !this.dialog) {
    //     this.dialog = true
    //     this.touched = true
    //     // Remove focus from input
    //     setTimeout(() => {
    //       document.activeElement.blur()
    //     }, 1)
    //   }
    // },
    openPicker() {
      this.dialog = true
      // Remove focus (weird bug where it highltights the clear icon)
      setTimeout(() => {
        document.activeElement.blur()
      }, 1)
    },
    // Called when date picker is used
    pickerSelect(value) {
      this.dialog = false
      this.$emit('input', value)
    },
    clearDate() {
      this.date = null
      this.$emit('input', null)
    },
    save(date) {
      this.$refs.menu.save(date)
    },
    setFromISO(value) {
      if (!value) {
        this.date = null
        return
      }
      // Convert iso format to dd/mm/yyyy
      let date = DateTime.fromISO(this.value)
      if (date && date.isValid) {
        this.date = date.toFormat('dd/MM/yyyy')
      } else {
        this.date = null
      }
    },
    validate() {
      // Clear validation error
      this.validationError = null

      // If no date, set to null
      if (!this.date) {
        this.$emit('input', null)
        return
      }
      // Try and convert to date object
      // First try yyyy
      let date = DateTime.fromFormat(this.date, 'd/M/yyyy')
      if (date && date.isValid) {
        this.$emit('input', date.toISODate())
        return
      }
      // Secondly try yy
      date = DateTime.fromFormat(this.date, 'd/M/yy')
      if (date && date.isValid) {
        this.$emit('input', date.toISODate())
        return
      }
      // If we get here, we have an invalid date
      this.validationError = 'dd/mm/yyyy'
    },
  },

  computed: {
    errorMessages() {
      let errors = []
      if (this.errors) {
        errors = this.errors.map((error) => error.$message)
      }
      // Add validation error if present
      if (this.validationError) {
        errors.push(this.validationError)
      }
      return errors
    },
    isMobile() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent)
    },
    placeholderText() {
      if (this.$attrs.placeholder) {
        return this.$attrs.placeholder
      } else {
        return 'dd/mm/yyyy'
      }
    },
    reftest() {
      return this.$refs['text-input']
    },
    attrs() {
      let attrs = {
        ...this.$attrs,
      }
      // Add in class and style which isn't included by default in vue 2
      // Need this to bind to the v-text-field non root element instead
      // of the root element
      attrs.class = this.$vnode.data.staticClass
      attrs.style = this.$vnode.data.staticStyle
      return attrs
    },
  },

  watch: {
    value(newValue) {
      this.validationError = null
      this.setFromISO(newValue)
    },
    menu(val) {
      if (this.birthday) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
      }
    },
  },
}
</script>

<style>
/* Hide the default html calendar icon */
input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
}
.cal-icon {
  /* Make icon central with date text when outlined input is used */
  margin-top: 0px;
}

/* Date clear visibibility */
.date-clear {
  /* Set to hidden by default */
  visibility: hidden;
}

.v-input--is-focused .date-clear {
  /* Show when focused */
  visibility: visible;
}

/* To give the actual date text part the min width set to something silly.
We need this otherwise the input overflows on small screens / cols */
.date-picker:not(.v-input--is-disabled) .v-text-field__slot {
  width: 10px;
}

input::-webkit-date-and-time-value {
  text-align: left;
}

.date-picker {
  min-width: 140px;
}
</style>
