import api from '@/services/axios.js'
import { newWebSocket } from '@/services/websocket'
import { openLink } from '@/helpers/utility'

export const namespaced = true

export const state = {
  pending: {},
}

export const mutations = {
  ADD_TO_PENDING(state, { download_id, obj }) {
    // Add to the pending object
    state.pending = Object.assign({}, state.pending, { [download_id]: obj })
  },
  REMOVE_FROM_PENDING(state, download_id) {
    delete state.pending[download_id]
    state.pending = Object.assign({}, state.pending)
  },
  UPDATE_MESSAGE(state, { download_id, message }) {
    state.pending[download_id].message = message
  },
}

export const actions = {
  startDownload({ commit, dispatch }, { route, filename }) {
    let obj = {
      filename: filename,
      message: null,
    }

    // Assign the id id based on length of array + 1
    let download_id = Object.keys(state.pending).length + 1
    commit('ADD_TO_PENDING', { download_id, obj })

    return api
      .get(route)
      .then((res) => {
        // If the response is a websocket, then we need to open a websocket
        if (res.data.substring(0, 4) == '/ws/') {
          const ws = newWebSocket(res.data)
          ws.onmessage = (e) => {
            const data = JSON.parse(e.data)
            if ('url' in data) {
              openLink(data.url)
              commit('REMOVE_FROM_PENDING', download_id)
              return
            } else if ('message' in data) {
              commit('UPDATE_MESSAGE', {
                download_id,
                message: data.message,
              })
            }
          }
        } else if (res.data.substring(0, 10) == '/download/') {
          // If the response is a download link, then we can just open it
          openLink(res.data)
          commit('REMOVE_FROM_PENDING', download_id)
          return
        } else {
          // Anything else treat as an error
          dispatch(
            'notification/add',
            {
              type: 'info',
              message: `${res.data}`,
            },
            { root: true }
          )
          commit('REMOVE_FROM_PENDING', download_id)
          return
        }
      })
      .catch((error) => {
        commit('REMOVE_FROM_PENDING', download_id)
        dispatch(
          'notification/add',
          {
            type: 'warning',
            message: `${error.response.data}`,
          },
          { root: true }
        )
        return
      })
  },
}

export const getters = {
  pending(state) {
    return state.pending
  },
  activeDownloads(state) {
    return !!Object.keys(state.pending).length
  },
}
