import store from '@/store'

const membersRoutes = [
  {
    path: '/members',
    name: 'Members',
    meta: { permissions: [], linkTag: 'members' },
    component: () =>
      import(
        /* webpackChunkName: "members" */ '@/views/members/MembersList.vue'
      ),
    // beforeEnter(routeTo, routeFrom, next) {
    //   store
    //     .dispatch(`members/loadList`, { params: routeTo.params })
    //     .then(() => next())
    // },
  },
  {
    path: '/members/add',
    name: 'MembersAdd',
    meta: { parent: 'Members', permissions: [], linkTag: 'members' },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "members-add" */ '@/views/members/MembersAdd.vue'
      ),
  },
  {
    path: '/members/:id',
    name: 'MembersDetail',
    meta: { parent: 'Members', permissions: [], linkTag: 'members' },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "members" */ '@/views/members/MembersDetail.vue'
      ),
    beforeEnter(routeTo, routeFrom, next) {
      store
        .dispatch('members/loadInstance', { params: routeTo.params })
        .then(() => next())
    },
  },
]

export default membersRoutes
