import { cloneDeep } from 'lodash'
import api from '@/services/axios.js'
import store from '@/store'
import { vuelidateInit } from '@/helpers/validation'

export function setFormErrors(initErrors, apiError) {
  // Deprecated in favour of setServerErrors
  // Create a blank error object using the initErrors 'template'
  // We need this in order to ensure nested fields are specified and dont produce errors
  let formErrors = initErrors

  if (apiError.response.status == 400) {
    for (let error in apiError.response.data) {
      formErrors[error] = apiError.response.data[error]
    }
    // return merge(formErrors, apiError.response.data)
    return formErrors
  } else {
    // Return existing form errors
    return formErrors
  }
}

export function setServerErrors({
  apiResponse,
  nonFieldErrors,
  vuelidateExternalResults,
} = {}) {
  // Function will operate on parameters by reference so no need to return anything
  let uncaughtErrors = {}
  let caughtErrors = {}

  if (nonFieldErrors != null) {
    // Reset non field errors if we are displaying them manually
    nonFieldErrors.splice(0)
  }

  // let fieldsToInclude = Object.keys(vuelidateExternalResults)

  if (apiResponse.response.status == 400) {
    for (let field in apiResponse.response.data) {
      if (nonFieldErrors != null && field == 'non_field_errors') {
        Object.assign(nonFieldErrors, apiResponse.response.data[field])
      } else if (
        field in vuelidateExternalResults &&
        vuelidateExternalResults[field] != undefined
      ) {
        caughtErrors[field] = apiResponse.response.data[field]
      } else {
        uncaughtErrors[field] = apiResponse.response.data[field]
      }
    }
  }

  Object.assign(vuelidateExternalResults, caughtErrors)

  if (Object.keys(uncaughtErrors).length) {
    // Display non field errors one at a time
    if (Object.keys(uncaughtErrors).includes('non_field_errors')) {
      for (let error of uncaughtErrors['non_field_errors']) {
        store.dispatch('notification/add', {
          type: 'warning',
          message: error,
        })
      }
    }

    delete uncaughtErrors['non_field_errors']

    // If anything still remaining output as one big warning
    if (Object.keys(uncaughtErrors).length) {
      store.dispatch('notification/add', {
        type: 'warning',
        message: uncaughtErrors,
      })
    }
  }
}

// Function to output non form errors to notification module
export function notifyErrors(error) {
  for (let errorMsg of error.response.data) {
    store.dispatch('notification/add', {
      type: 'warning',
      message: errorMsg,
    })
  }
}

// Function to output a success message for an update
export function flashSuccess() {
  store.dispatch('notification/add', {
    type: 'success',
    message: 'Changes saved successfully',
  })
}

export function flashDeleted() {
  store.dispatch('notification/add', {
    type: 'success',
    message: 'Record deleted',
  })
}

export function getTempID() {
  // Use when we need a key in a loop after inserting a new blank object without
  // an ID
  return 'temp_' + Math.floor(Math.random() * 10000 + 1)
}

// Sentinel validation function in order to ensure vuelidate has something to
// 'track' the field. Without this if no validations are defined it will not merge
// any server errors correctly
const sentinel = () => true

/*--------------------------------
formMixin
---------------------------------*/
export const formMixin = {
  setup: () => ({ v$: vuelidateInit() }),

  props: {
    edit: {
      required: false,
      type: Object,
    },
    editableKey: {
      required: false,
      default: '',
    },
  },

  data() {
    return {
      data: {},
      disabled: false,
      keepNested: [], // Use to keep the object and not flatten when editing the data
      nonFieldErrors: null, // Override as a list on the component to handle non-field errors
      vuelidateExternalResults: {
        data: {},
      },
      apiOptions: {},
      supressFormReset: false, // Set to true to prevent form being reset after create/update
      flashSuccess,
      flashDeleted,
      setServerErrors,
      getTempID,
      sentinel,
    }
  },

  created() {
    this.initForm()
    this.postInit()
  },

  methods: {
    submit() {
      // Reset non field errors
      if (this.nonFieldErrors != null) {
        this.nonFieldErrors = []
      }
      // Run vuelidate validation
      this.v$.$reset()
      this.v$.$validate().then((isValid) => {
        if (!isValid) {
          store.dispatch('notification/add', {
            type: 'warning',
            message: 'Please correct form errors',
          })
          return
        }
        if (this.edit) {
          this.update()
        } else {
          this.create()
        }
      })
    },

    create() {
      this.disabled = true
      this.$NProgress.start()
      let payload = this.createPayload()
      api
        .post(this.createEndpoint, payload, this.apiOptions)
        .then((response) => {
          this.afterCreate(response)
        })
        .catch((error) => {
          this.$NProgress.done()
          this.disabled = false
          this.errors = setFormErrors(this.initErrors(), error)
          setServerErrors({
            apiResponse: error,
            nonFieldErrors: this.nonFieldErrors,
            vuelidateExternalResults: this.vuelidateExternalResults.data,
          })
          this.onError(error)
        })
    },

    update() {
      this.disabled = true
      this.$NProgress.start()
      let payload = this.createPayload()
      api
        .patch(this.updateEndpoint, payload)
        .then((response) => {
          this.afterUpdate(response)
        })
        .catch((error) => {
          this.$NProgress.done()
          this.disabled = false
          //this.errors = setFormErrors(this.initErrors(), error)
          setServerErrors({
            apiResponse: error,
            nonFieldErrors: this.nonFieldErrors,
            vuelidateExternalResults: this.vuelidateExternalResults.data,
          })
        })
    },

    deleteInstance() {
      this.disabled = true
      this.$NProgress.start()
      api
        .delete(this.updateEndpoint)
        .then(() => {
          this.afterDelete()
        })
        .catch((error) => {
          this.$NProgress.done()
          this.disabled = false
          setServerErrors({
            apiResponse: error,
            nonFieldErrors: this.nonFieldErrors,
            vuelidateExternalResults: this.vuelidateExternalResults.data,
          })
        })
    },

    afterUpdate(response) {
      if (this.moduleName) {
        this.$store.dispatch(`${this.moduleName}/updateInstance`, {
          data: response.data,
        })
      }
      this.$NProgress.done()
      this.disabled = false
      this.$store.dispatch('app/cancelEdit', this.editableKey).then(() => {
        this.$emit('updated', response)
      })
      flashSuccess()
    },

    afterCreate(response) {
      // Component will emit the created event but we can also override this
      // method at the component level for custom functionality
      this.$store.dispatch('app/cancelEdit', this.editableKey)
      this.disabled = false
      this.$NProgress.done()
      this.$emit('created', response)
      return
      // this.$router.push({
      //   name: this.redirectView,
      //   params: { forceRefresh: true },
      // })
    },

    afterDelete() {
      this.$NProgress.done()
      this.disabled = false
      this.$store.dispatch('app/cancelEdit', this.editableKey)
      this.$emit('deleted')
      flashDeleted()
      return
    },
    // eslint-disable-next-line no-unused-vars
    onError(error) {
      // Hook to do something when we get an error
      return
    },

    cancel() {
      this.$store.dispatch('app/cancelEdit', this.editableKey)
      this.$emit('canceled')
    },

    initErrors() {
      return {}
    },

    postInit() {
      return
    },

    initForm() {
      // Edit property is to be set as a prop in the router
      if (this.edit) {
        // Loop through each field in the data object
        for (let field in this.data) {
          // Copy across the data from the instance to edit which is passed as a prop
          this.data[field] = cloneDeep(this.edit[field])

          // Decide whether to faltten the field if specified
          // Note arrays are also considered objects in JS
          // if (
          //   Object.prototype.toString.call(this.data[field]) ===
          //     '[object Object]' &&
          //   !this.keepNested.includes(field)
          // ) {
          //   // If it's an object then faltten to just to id field
          //   this.data[field] = this.data[field]['id']
          // }
        }
      }
    },

    initEdit() {
      // Can use this method as a hook to do something when editable changed to true
      return
    },
    startProgress() {
      this.disabled = true
      this.$NProgress.start()
    },
    endProgress() {
      this.disabled = false
      this.$NProgress.done()
    },

    onEditExit() {
      // Hook to do something when we exit the edit state (either via cancel or submit)
      return
    },

    flatten(data, keepNested) {
      let res = cloneDeep(data)
      for (let field in res) {
        let value = res[field]
        if (
          value &&
          Object.prototype.toString.call(res[field]) == '[object Object]' &&
          !keepNested.includes(field)
        ) {
          res[field] = res[field].id
        }
      }
      return res
    },

    createPayload() {
      // By default will just use the computed property payload
      // If we are doing things such as flattening and cloning the data object
      // better to do it with this method which we will call before submitting.
      // Can therefore defer the computation required until just before submission
      // rather than continuously as is the case of using payload
      return this.payload
    },
  },

  computed: {
    createEndpoint() {
      let endpoint = this.$store.getters[`${this.moduleName}/endpoint`]
      return `${endpoint}.json`
    },
    updateEndpoint() {
      if (!this.data.id) {
        return null
      } else {
        let endpoint = this.$store.getters[`${this.moduleName}/endpoint`]
        return `${endpoint}/${this.data.id}.json`
      }
    },
    instance() {
      if (this.edit) {
        return this.$store.getters[`${this.moduleName}/instance`]
      } else {
        // Name property so the pageInfoData computed func doesn't error out
        return { name: '' }
      }
    },
    payload() {
      // Computed property for the payload we will submit to the API
      // Can be overidden by the components to merge data etc
      return this.data
    },
    editable() {
      // If no editableKey is passed then assume we can edit by default
      if (!this.editableKey) {
        return true
        // If editableKey specified and app store contains te key
        // then we are in edit mode
      } else if (this.$store.getters['app/isEditable'](this.editableKey)) {
        return true
      } else {
        return false
      }
    },
    viewOnly() {
      // Use this to ensure when we bind to disabled we are not using
      // a double negative
      return !this.editable
    },
  },

  watch: {
    editable(val) {
      // Val is the state of the prop when it changes
      // If editable is false then we want to reset our data so changes don't persist
      if (!val && !this.supressFormReset) {
        this.initForm()
        this.postInit()
        this.v$.$reset()
        this.onEditExit()
      } else {
        this.initEdit()
      }
    },
  },
}

/*--------------------------------
crudViewMixin
---------------------------------*/

export const crudViewMixin = {
  props: {
    edit: {
      required: false,
      type: Boolean,
    },
  },

  data() {
    return {
      disabled: false,
      forceRefresh: true,
    }
  },

  methods: {
    goTo(route, forceRefresh) {
      this.$cancelEdit(this.moduleName)
      this.$router.push({ name: route, params: { forceRefresh: forceRefresh } })
    },
  },

  computed: {
    instance() {
      if (this.edit) {
        return this.$store.getters[`${this.moduleName}/instance`]
      } else {
        // Name property so the pageInfoData computed func doesn't error out
        return null
      }
    },
    module() {},
    pageInfoComputed() {
      // Take the pageInfo object and set the title to the add / edit Title as required
      let pageInfo = this.pageInfo
      if (this.edit) {
        pageInfo['title'] = pageInfo['editTitle']
      } else {
        pageInfo['title'] = pageInfo['addTitle']
      }
      return pageInfo
    },
  },
}

/*--------------------------------
DeleteMixin
---------------------------------*/

export const deleteMixin = {
  methods: {
    deleteInstance({ deleteEndpoint, redirectView }) {
      this.$NProgress.start()
      this.disabled = true
      api
        .delete(deleteEndpoint)
        .then(() => {
          this.$router.push({
            name: redirectView,
            params: { forceRefresh: true },
          })
        })
        .catch((error) => {
          this.$NProgress.done()
          this.disabled = false
          this.dialog = false
          notifyErrors(error)
        })
    },
  },
}
