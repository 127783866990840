<template>
  <v-container fluid :class="{ 'pt-0': $vuetify.breakpoint.smAndUp }">
    <v-row>
      <v-col cols="auto">
        <slot name="title">
          <div class="d-flex">
            <v-icon v-if="icon" class="mr-2" large>{{ icon }}</v-icon>
            <div class="page-title">
              {{ pageInfo.title }}
            </div>
          </div>
        </slot>
        <div
          class="breadcrumbs mt-2"
          v-if="pageInfo.breadcrumbs && pageInfo.breadcrumbs.length"
        >
          <ul class="d-flex flex-wrap">
            <li v-for="(crumb, index) in pageInfo.breadcrumbs" :key="index">
              <span
                tabindex="-1"
                v-if="crumb.route"
                @click="$router.push(crumb.route)"
                class="breadcrumb-link"
                >{{ crumb.label }}</span
              >
              <!-- <router-link
                tabindex="-1"
                v-if="crumb.route"
                :to="crumb.route"
                class="breadcrumb-link"
                >{{ crumb.label }}</router-link
              > -->
              <span v-else>{{ crumb.label }}</span>
              <span v-if="index != pageInfo.breadcrumbs.length - 1" class="px-2"
                ><v-icon>mdi-chevron-right</v-icon></span
              >
            </li>
          </ul>
        </div>
      </v-col>
      <v-spacer></v-spacer>
      <v-col
        cols="auto"
        class="d-flex justify-start align-center"
        v-if="showActions"
      >
        <slot name="actions"> </slot>
      </v-col>
    </v-row>
  </v-container>
  <!-- <div class="d-flex justify-space-between align-center mb-3">
    <div class="page-info">
      <slot name="title">
        <div class="text-h4 font-weight-light">{{ pageInfo.title }}</div>
      </slot>
      <div
        class="breadcrumbs mt-2"
        v-if="showBreadcrumbs && pageInfo.breadcrumbs"
      >
        <ul class="d-flex">
          <li v-for="(crumb, index) in pageInfo.breadcrumbs" :key="index">
            <span v-if="index != 0" class="px-2"
              ><v-icon>mdi-chevron-right</v-icon></span
            >
            <router-link
              v-if="crumb.route"
              :to="crumb.route"
              class="breadcrumb-link"
              >{{ crumb.label }}</router-link
            >
            <span v-else>{{ crumb.label }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="page-actions">
      <slot name="actions"> </slot>
    </div>
  </div> -->
</template>

<script>
export default {
  props: {
    pageInfo: {
      required: true,
      type: Object,
    },
    showActions: {
      type: Boolean,
      default: true,
    },
    icon: {
      required: false,
    },
  },
}
</script>

<style scoped>
.breadcrumbs ul {
  padding-left: 0;
}
.breadcrumbs li {
  list-style: none;
}

.breadcrumb-link {
  text-decoration: none;
  cursor: pointer;
}
</style>
