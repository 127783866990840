<template>
  <div>
    <div
      v-if="labelAbove"
      class="label-above"
      :class="{ 'error--text': errorMessages.length }"
    >
      {{ $attrs.label }}
    </div>
    <v-textarea
      class="base-textarea"
      outlined
      v-bind="$attrs"
      v-on="listeners"
      :error-messages="errorMessages"
      :label="labelAbove ? null : $attrs.label"
      hideDetails="auto"
    ></v-textarea>
  </div>
</template>

<script>
export default {
  name: 'BaseTextArea',

  props: {
    errors: {
      required: false,
      default() {
        return []
      },
    },
    labelAbove: {
      required: false,
      type: Boolean,
    },
  },

  methods: {},

  computed: {
    listeners() {
      return {
        ...this.$listeners,
      }
    },
    errorMessages() {
      if (this.errors) {
        return this.errors.map((error) => error.$message)
      } else {
        return []
      }
    },
  },
}
</script>

<style lang="scss">
.base-textarea {
  textarea {
    line-height: 22px;
  }
  .v-text-field__slot {
    padding-bottom: 5px;
  }
  label {
    top: 10px !important;
  }
}

.label-above {
  font-size: 1em;
  margin-bottom: 2px;
}
</style>
