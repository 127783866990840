import store from '@/store'

const vouchersRoutes = [
  {
    path: '/voucher-registration/:baseName',
    name: 'VoucherRegistration',
    meta: { permissions: [], linkTag: '' },
    component: () => import('@/views/vouchers/VoucherRegister.vue'),
  },
  {
    path: '/vouchers',
    name: 'Vouchers',
    meta: { permissions: [], linkTag: 'vouchers' },
    component: () => import('@/views/vouchers/VoucherList.vue'),
    beforeEnter(routeTo, routeFrom, next) {
      Promise.all([
        // store.dispatch('vouchers/loadList', { params: routeTo.params }),
        store.dispatch('vouchers/fetchDrafts'),
      ]).then(() => {
        next()
      })
    },
  },
  {
    path: '/vouchers/add',
    name: 'VoucherAdd',
    meta: { permissions: [], linkTag: 'vouchers' },
    component: () => import('@/views/vouchers/VoucherAdd.vue'),
  },
  {
    path: '/vouchers/:id/add-internal',
    name: 'VoucherInternalAdd',
    meta: { permissions: [], linkTag: 'vouchers' },
    component: () => import('@/views/vouchers/VoucherInternalAdd.vue'),
    beforeEnter(routeTo, routeFrom, next) {
      store
        .dispatch('vouchers/loadInstance', { params: routeTo.params })
        .then(() => next())
    },
  },
  {
    path: '/vouchers/:id/',
    name: 'VoucherDetail',
    meta: { permissions: [], linkTag: 'vouchers' },
    component: () => import('@/views/vouchers/VoucherDetail.vue'),
    beforeEnter(routeTo, routeFrom, next) {
      store
        .dispatch('vouchers/loadInstance', { params: routeTo.params })
        .then(() => {
          // If it's a draft, redirect to the internal add page
          let voucher = store.getters['vouchers/instance']
          if (voucher.draft) {
            // Pass instance to prevent fetching the voucher again
            next({
              name: 'VoucherInternalAdd',
              params: { id: voucher.id, instance: voucher },
            })
          } else {
            next()
          }
        })
    },
  },
  {
    path: '/vouchers/:id/edit',
    name: 'VoucherEdit',
    meta: { permissions: [], linkTag: 'vouchers' },
    component: () => import('@/views/vouchers/VoucherEdit.vue'),
    beforeEnter(routeTo, routeFrom, next) {
      store
        .dispatch('vouchers/loadInstance', { params: routeTo.params })
        .then(() => next())
    },
  },
]

export default vouchersRoutes
