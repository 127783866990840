import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#2c3d56',
        primaryOriginal: '#003180',
        accent: '#A37E33',
        secondary: '#2c3d56', // Used for CTA
        error: '#b71c1c',
        // background: '#f2f5f8',
        background: '#ededed',
      },
      dark: {
        primary: '#1976d2',
      },
    },
    options: {
      customProperties: true,
    },
  },
  breakpoint: {
    // Default is the xs breakpoint used for mobile
    // sm is then used to set appearance on portrait tablet
    // md for landscape tablet / laptop
    // lg for desktop / full screen mac laptop
    mobileBreakpoint: 'xs', // This is equivalent to a value of 600 and below
    thresholds: {
      xs: 768, // When sm breakpoint kicks in (portrait tablet)
      sm: 1024, // When md breakpoint kicks in (landscape tablet / laptop)
      md: 1696, // When lg breakpoint kicks in (desktop / full screen mac laptop)
      lg: 1920, // When xl starts. Kicks in at 1904 however (scroll bar width?)
    },
  },
})
