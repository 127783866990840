<template>
  <v-alert v-if="errors.length" outlined color="error" dense class="mb-7">
    <ul class="error-list">
      <li v-for="error in errors" :key="error">
        {{ error }}
      </li>
    </ul>
  </v-alert>
</template>

<script>
export default {
  name: 'BaseNonFieldErrors',
  props: {
    errors: {
      required: false,
      type: Array,
    },
  },
  computed: {},
}
</script>

<style>
ul {
  text-align: center;
  padding-left: 0px !important;
  list-style: none;
}
</style>
