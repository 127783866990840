<template>
  <v-navigation-drawer
    app
    class="elevation-5"
    :value="navDrawer"
    color="primary"
    dark
    v-on="$listeners"
    :mobile-breakpoint="1280"
    :width="loggedIn ? 256 : 0"
    touchless
  >
    <v-container class="pt-1" v-if="loggedIn">
      <div class="nav-top-wrapper">
        <v-img
          alt="Brand Logo"
          class="shrink ml-2"
          contain
          :src="siteLogo"
          transition="scale-transition"
          width="50"
        />
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="nav-title">
              {{ siteName }}
            </v-list-item-title>
            <v-list-item-subtitle
              >{{ user ? user.first_name : '' }}
              {{ user ? user.last_name : '' }}</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-container>

    <v-list dense nav :key="navBarKey" v-if="loggedIn">
      <v-list-item-group
        :value="activeRoute"
        :mandatory="makeMandatory"
        color="white"
      >
        <v-list-item
          link
          @click="goTo(item.route)"
          v-for="item in authorisedLinks"
          :key="item.route"
          tabindex="-1"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-badge
              inline
              :content="counters[item.counter]"
              color="white"
              class="counter-badge"
              v-if="item.counter && counters[item.counter] > 0"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-badge>
            <v-list-item-title v-else>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
      <!-- Support for staff view -->
      <v-list-item
        tabindex="-1"
        v-if="staffMode && tenantConfig.internal_docs_url"
        @click="openDocs"
      >
        <v-list-item-icon>
          <v-icon>mdi-help-circle</v-icon>
        </v-list-item-icon>
        <v-list-item-title> Operations QRH </v-list-item-title>
      </v-list-item>
    </v-list>
    <!-- <template v-slot:append>
      <div class="d-flex pa-2 align-center white--text">
        <v-icon class="mr-2">mdi-help-circle-outline</v-icon>
        <div>View Support Docs</div>
      </div>
    </template> -->
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import { getSubdomain } from '@/helpers/utility.js'

export default {
  name: 'NavBar',

  props: {
    navDrawer: {
      required: true,
    },
  },

  data() {
    return {
      key: 1,
      right: null,
      active: null,
    }
  },

  methods: {
    goTo(route) {
      this.$router.push({ name: route })
    },
    openDocs() {
      window.open(this.tenantConfig.internal_docs_url, '_blank')
    },
  },

  computed: {
    activeRoute() {
      let index = this.authorisedLinks.findIndex((item) => {
        return (
          item.route === this.$route.name ||
          item.route === this.$route.meta.parent ||
          item.linkTag === this.$route.meta.linkTag
        )
      })
      return index
    },
    makeMandatory() {
      if (this.activeRoute != -1) {
        return true
      } else {
        return false
      }
    },
    siteLogo() {
      if (this.tenantBranding) {
        return this.tenantBranding.asset_href + 'logo_dark.png'
      } else {
        return this.$appConfig.siteLogo
      }
    },
    siteName() {
      if (this.tenantBranding) {
        return this.tenantBranding.name
      } else {
        return this.$appConfig.siteTitle
      }
    },
    subDomain() {
      return getSubdomain()
    },
    counters() {
      return {
        outstandingNotices: this.$user.outstanding_notices_count,
      }
    },
    ...mapGetters('user', [
      'loggedIn',
      'hasPerm',
      'hasPerms',
      'user',
      'staffMode',
    ]),
    ...mapGetters('app', ['tenantBranding', 'navBarKey', 'tenantConfig']),
    ...mapGetters('nav', ['authorisedLinks']),
  },
}
</script>

<style scoped>
.nav-top-wrapper {
  display: flex;
}
</style>

<style>
.counter-badge .v-badge__badge {
  color: black !important;
}
</style>
