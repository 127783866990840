<template>
  <v-app-bar app color="primary" dark dense>
    <v-container fluid class="mx-0">
      <div
        class="d-flex align-center justify-space-between"
        v-if="loggedIn && subDomain"
      >
        <v-app-bar-nav-icon
          v-if="$vuetify.breakpoint.width < 1280"
          @click="$emit('drawer-toggle')"
        ></v-app-bar-nav-icon>
        <v-spacer></v-spacer>
        <BaseMenu v-if="$base" />
        <div class="vertical-divide"></div>
        <UserMenu />
      </div>
      <div v-else class="d-flex align-center">
        <v-img
          alt="Brand Logo"
          class="shrink cursor mr-4 logo"
          contain
          :src="siteLogo"
          transition="scale-transition"
          @click="goToHome"
        />

        <span class="site-title cursor" @click="goToHome">{{ siteName }}</span>
      </div>
    </v-container>
  </v-app-bar>
</template>

<script>
import UserMenu from '@/components/app/UserMenu.vue'
import BaseMenu from '@/components/app/BaseMenu.vue'
import { mapGetters } from 'vuex'
import { getSubdomain } from '@/helpers/utility.js'

export default {
  name: 'NavBar',
  components: { UserMenu, BaseMenu },

  data() {
    return {}
  },

  methods: {
    goToHome() {
      this.$store.dispatch('app/goToHome', this.$route)
    },
  },

  computed: {
    siteLogo() {
      if (this.tenantBranding) {
        return this.tenantBranding.asset_href + 'logo_dark.png'
      } else {
        return null
      }
    },
    siteName() {
      if (this.tenantBranding) {
        return this.tenantBranding.name
      } else {
        return this.$appConfig.siteTitle
      }
    },
    subDomain() {
      return getSubdomain()
    },
    ...mapGetters('user', ['loggedIn']),
    ...mapGetters('app', ['tenantBranding']),
  },
}
</script>

<style scoped>
.cursor {
  cursor: pointer;
}

.vertical-divide {
  border-right: 1px solid white;
  width: 10px;
  display: block;
  height: 25px;
  margin-right: 16px;
  margin-left: 5px;
}
/* .account-switch {
  font-size: 0.88rem !important;
  margin-left: 13px !important;
} */
.logo {
  max-width: 40px;
  height: 30px;
}
</style>
