import store from '@/store'

const adminRoutes = [
  {
    path: '/app-admin',
    name: 'Admin',
    meta: { permissions: [], linkTag: 'admin' },
    component: () =>
      import(/* webpackChunkName: "admin" */ '@/views/admin/Admin.vue'),
  },
  {
    path: '/reports',
    name: 'Reports',
    meta: { permissions: ['tenant_admin'], linkTag: 'admin' },
    component: () => import('@/views/admin/Reports.vue'),
  },
  {
    path: '/exports',
    name: 'Exports',
    meta: { permissions: ['tenant_export'], linkTag: 'admin' },
    component: () => import('@/views/admin/Exports.vue'),
  },
  {
    path: '/test',
    name: 'TestPage',
    meta: { permissions: ['tenant_admin'], linkTag: 'admin' },
    component: () => import('@/views/admin/TestPage.vue'),
  },
  {
    path: '/instructors',
    name: 'Instructors',
    meta: { permissions: ['tenant_admin'], linkTag: 'admin' },
    component: () => import('@/views/admin/instructors/InstructorsList.vue'),
  },
  {
    path: '/instructors/:id',
    name: 'InstructorDetail',
    meta: { permissions: ['tenant_admin'], linkTag: 'admin' },
    component: () => import('@/views/admin/instructors/InstructorDetail.vue'),
    beforeEnter(routeTo, routeFrom, next) {
      store
        .dispatch(`instructors/loadInstance`, { params: routeTo.params })
        .then(() => next())
    },
  },
]

export default adminRoutes
