export const namespaced = true

export const state = {
  notifications: [],
}

let nextId = 1

export const mutations = {
  PUSH(state, notification) {
    state.notifications.push({
      ...notification,
      id: nextId++,
    })
  },
  DELETE(state, notificationToRemove) {
    state.notifications = state.notifications.filter(
      (notification) => notification.id !== notificationToRemove.id
    )
  },
  REMOVE_ALL(state) {
    state.notifications = []
  },
}
export const actions = {
  add({ commit, state }, notification) {
    // If we are specifically error handling certain messages then include
    // them in this list to supress them from the notification bar to ensure
    // they are not shown twice the user
    const ignoreList = ['Please enter valid credentials']
    if (!ignoreList.includes(notification.message)) {
      if (state.notifications.length > 0) {
        commit('REMOVE_ALL') // Remove all existing notifications
        // Wait for the animation to finish before adding new notification
        setTimeout(() => {
          commit('PUSH', notification)
        }, 300)
      } else {
        commit('PUSH', notification)
      }
    }
  },
  remove({ commit }, notificationToRemove) {
    commit('DELETE', notificationToRemove)
  },

  flashDeleted({ dispatch }) {
    dispatch('add', {
      type: 'success',
      message: 'Deleted',
    })
  },

  flashUpdated({ dispatch }) {
    dispatch('add', {
      type: 'success',
      message: 'Updated',
    })
  },

  flashCreated({ dispatch }) {
    dispatch('add', {
      type: 'success',
      message: 'Created',
    })
  },
}
