<template>
  <v-row>
    <v-col class="pb-0">
      <div class="heading"><slot></slot></div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'BaseHeading',
}
</script>
