import store from '@/store'
import { getSubdomain } from '@/helpers/utility.js'

const userRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/user/Login.vue'),
    beforeEnter: (to, from, next) => {
      // Check to see if user is logged in
      let loggedIn = store.getters['user/loggedIn']
      // Only allow routing if a subdomain has been specified
      if (loggedIn || !getSubdomain()) {
        next({ name: 'Index' })
      } else {
        next()
      }
    },
  },
  {
    path: '/logout',
    name: 'Logout',
    beforeEnter: (to, from, next) => {
      if (store.getters['user/loggedIn']) {
        store.dispatch('user/logout')
      } else {
        next({ name: 'Index' })
      }
    },
  },
  {
    path: '/password-create/:uid/:token',
    name: 'PasswordCreate',
    meta: { permissions: [] },
    props: {
      mode: 'create',
    },
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/user/PasswordReset.vue'),
  },
  {
    path: '/password-reset/:uid/:token',
    name: 'PasswordReset',
    meta: { permissions: [] },
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/user/PasswordReset.vue'),
  },
  {
    path: '/password-change',
    name: 'PasswordChange',
    meta: { permissions: [] },
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/user/PasswordChange.vue'),
  },
  {
    path: '/token-expired',
    name: 'TokenExpired',
    meta: { permissions: [] },
    props: true,
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/user/TokenExpired.vue'),
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: { permissions: [] },
    component: () =>
      import(/* webpackChunkName: "profile" */ '@/views/user/Profile.vue'),
  },
]

export default userRoutes
