<template>
  <v-container fluid :class="{ 'height-100': fillHeight }">
    <v-card
      class="card"
      :class="{ 'card-error': error, 'fill-height': fillHeight }"
      v-bind="$attrs"
      outlined
    >
      <v-container fluid class="card-container">
        <v-row v-if="displayHeader" align="center">
          <v-col cols="auto">
            <slot name="title">
              <div
                class="text-h6 font-weight-bold"
                :class="{ 'error--text': error }"
              >
                {{ title }}
              </div>
            </slot>
          </v-col>
          <v-spacer v-if="$slots.actions || editableKey"></v-spacer>
          <v-col cols="auto" v-if="$slots.actions">
            <slot name="actions"></slot>
          </v-col>
          <v-col cols="auto" v-if="!$slots.actions && editableKey">
            <v-btn
              icon
              v-if="!$editing(editableKey)"
              @click="$editMode(editableKey)"
              ><v-icon>mdi-pencil</v-icon></v-btn
            >
          </v-col>
        </v-row>
        <slot name="body"></slot>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: {
    title: {
      required: false,
      type: String,
    },
    displayHeader: {
      type: Boolean,
      default: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
    fillHeight: {
      type: Boolean,
      default: false,
    },
    editableKey: {
      required: false,
    },
  },
}
</script>

<style scoped>
.card-container {
  padding: 18px;
}
@media screen and (max-width: 600px) {
  .card-container {
    padding: 22px;
  }
}
.card-error {
  border: 2px solid #b71c1c;
}
</style>
