import store from '@/store'

const flightsRoutes = [
  {
    path: '/flights',
    name: 'Flights',
    meta: { permissions: [], linkTag: 'flights' },
    component: () =>
      import(
        /* webpackChunkName: "flights" */ '@/views/flights/FlightList.vue'
      ),
    beforeEnter(routeTo, routeFrom, next) {
      store
        .dispatch(`flights/loadList`, { params: routeTo.params })
        .then(() => next())
    },
  },
  {
    path: '/logbook',
    name: 'MemberLogbook',
    meta: { permissions: [], linkTag: 'logbook' },
    component: () => import('@/views/flights/MemberLogbook.vue'),
    beforeEnter(routeTo, routeFrom, next) {
      store
        .dispatch(`flights/loadList`, { params: routeTo.params })
        .then(() => next())
    },
  },
  {
    path: '/timesheet',
    name: 'Timesheet',
    meta: { permissions: [], linkTag: 'timesheet' },
    component: () => import('@/views/flights/Timesheet.vue'),
  },
]

export default flightsRoutes
