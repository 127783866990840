<template>
  <div class="notification-container" :style="containerStyle">
    <NotificationBar
      v-for="notification in notifications"
      :key="notification.id"
      :notification="notification"
    />
  </div>
</template>

<script>
import NotificationBar from '@/components/app/NotificationBar.vue'
import { mapState } from 'vuex'

export default {
  components: {
    NotificationBar,
  },
  props: {
    navDrawer: {
      required: true,
    },
  },
  computed: {
    containerStyle() {
      if (this.navDrawer && this.$user) {
        return {
          width: 'calc(100% - 256px)',
          marginLeft: '256px',
        }
      } else {
        return {
          width: '100%',
        }
      }
    },
    ...mapState('notification', ['notifications']),
  },
}
</script>

<style scoped>
.notification-container {
  position: fixed;
  bottom: 10px;
  left: 0;
  padding-left: 6%;
  padding-right: 6%;
  z-index: 9999;
}
</style>
