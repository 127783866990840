<template>
  <div>
    <v-dialog :value="display" width="550" v-bind="$attrs" persistent>
      <v-card>
        <v-card-title class="text-h5">Confirm Navigation</v-card-title>
        <v-card-text>
          Are you sure you want to leave this page without saving?
        </v-card-text>
        <v-card-actions class="mx-1 pb-5 pt-1">
          <v-row>
            <v-col cols="12" sm="6">
              <v-btn
                color="warning"
                outlined
                @click="$store.dispatch('app/confirmNav')"
                width="100%"
                >Leave without saving</v-btn
              >
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="6">
              <v-btn
                color="primary"
                class="white--text"
                @click="$store.dispatch('app/cancelNav')"
                width="100%"
                >Continue Editing</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    display: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style></style>
