import useVuelidate from '@vuelidate/core'
import { helpers } from '@vuelidate/validators'
//import { DateTime } from 'luxon'

export function vuelidateInit() {
  return useVuelidate({ $autoDirty: true })
}

// Time Required Validation
//////////////////////////////

function timeRequiredValidator(value) {
  return /\d{2}:\d{2}/.test(value)
}

function dateValidator(value) {
  // If an ISO date is passed in (eg from date picker)
  // then can tr
  // Attempt to convert to dt object
  // let dt = DateTime.fromFormat(value, 'd/M/yyyy')
  // console.log(value, dt)
  // if (value && dt.invalid) {
  //   return false
  // } else {
  //   return true
  // }
  console.log('validator', value)
  return value == '2022-12-12'
}

// Requires destructing when used
export const timeRequired = {
  timeRequiredValidator: helpers.withMessage(
    'This field cannot be empty',
    timeRequiredValidator
  ),
}

export const validDate = {
  dateValidator: helpers.withMessage('dd/yyy', dateValidator),
}

export const sentinel = () => true
