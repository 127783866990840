// Loading of environement variables
// This is then imported into main.js for access througout the app if needed

export const appConfig = {
  // .env variables
  siteTitle: process.env.VUE_APP_SITE_TITLE
    ? process.env.VUE_APP_SITE_TITLE
    : 'FlightCloud',
  siteLogo: require('../assets/logo-generic.png'),
}

export default appConfig
